import { LoadingController } from "@ionic/angular";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LoadingIndicatorService {
  loading;

  constructor(private loadingController: LoadingController) {}

  async presentLoading(from: string = "none") {
    this.loading = await this.loadingController.create({
      cssClass: "loading-indic",
      // translucent: true,
      spinner: "lines",
      // message: 'Please wait...',
    });
    console.log(
      "LoadingIndicatorService.presentLoading() -> " +
        this.loading +
        "  - from: " +
        from
    );
    await this.loading.present();
  }

  public dismissLoading(from: string = "none" ): void {
    console.log(
      "LoadingIndicatorService.dismissLoading() -> " +
        this.loading +
        "  - from: " +
        from
    );
    if (this.loading) {
      this.loading.dismiss();
      //
    //   setTimeout(() => {
    //     this.loading = null;
    //   }, 100);
    }
  }
}
