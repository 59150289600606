import { CarPointPopupPipe } from "./../core/pipes/car-point-popup.pipe";
import { SummaryInterestEmptyPipe } from "./../core/pipes/summary-interest-empty.pipe";
import { PlatformCssPipe } from "./../core/pipes/platform-css.pipe";
import { LocaleSwitcherPipe } from "./../core/pipes/locale-switcher.pipe";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { HttpClientModule } from "@angular/common/http";
import { LoggerModule } from "ngx-logger";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatMenuModule } from "@angular/material/menu";
import { MatInputModule } from "@angular/material/input";
import { ReactiveFormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDialogModule } from "@angular/material/dialog";
import { MatStepperModule } from "@angular/material/stepper";
import { CarPointsPipe } from "../core/pipes/car-points.pipe";

@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    FlexLayoutModule,
    LoggerModule,
    HttpClientModule,

    // Materials Modules
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatCardModule,
    MatCheckboxModule,
    MatMenuModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatDialogModule,
    MatStepperModule,
    // LocaleSwitcherPipe
  ],
  declarations: [
    LocaleSwitcherPipe,
    PlatformCssPipe,
    SummaryInterestEmptyPipe,
    CarPointsPipe,
    CarPointPopupPipe,
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    LocaleSwitcherPipe,
    PlatformCssPipe,
    SummaryInterestEmptyPipe,
    CarPointsPipe,
    CarPointPopupPipe,

    // Materials Modules
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatCardModule,
    MatCheckboxModule,
    MatMenuModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatDialogModule,
    MatStepperModule,
  ],
  entryComponents: [],
  providers: [
    // LocaleSwitcherPipe
  ],
})
export class FNISharedModule {}
