import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localeSwitcher'
})
export class LocaleSwitcherPipe implements PipeTransform {

  constructor(private translate: TranslateService) { }

  transform(value: any, prefix: string): string {
    
    const sLang = this.translate.currentLang.replace('-', '_');
    let finalPrefix: string = '';
    if (prefix){
      finalPrefix = prefix + '_';
    }
    const nString = finalPrefix + sLang;
    return value[nString];
  }

}
