import { NetworkService } from "./core/services/network.service";
import { ResetHotspotService } from "./core/services/reset-hotspot.service";
import { PackageService } from "./core/services/package.service";
import { ErrorDialogModule } from "./components/error-dialog/error-dialog.module";
import { FirebaseAssetsService } from "./core/services/firebase-assets.service";
import { LoadingIndicatorService } from "./core/services/loading-indicator.service";
import { StateServiceService } from "./core/services/state-service.service";
import { VehiculesService } from "./core/services/vehicules.service";
import { JwtInterceptor } from "./core/interceptors/JwtInterceptor";
import { SlideCanDeactivateGuard } from "./core/guards/slide-can-deactivate-guard.service";
import { AuthInterceptor } from "./core/interceptors/AuthInterceptor";
import { SlideNavService } from "./core/services/slide-nav.service";
import { PresentationService } from "./core/services/presentation.service";
import { VideoService } from "./core/services/video.service";
import { firebaseConfig } from "./../environments/environment";
import { StorageService } from "./core/services/storage.service";
import { StreamingMedia } from "@ionic-native/streaming-media/ngx";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { FniApiService } from "./core/services/fni-api.service";
import { AuthService } from "./core/services/auth.service";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { FNISharedModule } from "./modules/fni-shared.module";
import { NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularFireModule } from "@angular/fire";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { LongPressDirective } from "./core/directive/long-press.directive";
import { TooltipModule } from "ng2-tooltip-directive";
import { InternalPlayerService } from "./core/services/internal_player.service";
import { GlobalErrorHandler } from "./core/errors/GlobalErrorHandler";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FNISharedModule,
    LoggerModule.forRoot({
      serverLoggingUrl: null,
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR,
    }),
    TranslateModule.forRoot({
      defaultLanguage: "fr-CA",
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule, // imports firebase/database, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    AngularFireStorageModule,
    ErrorDialogModule,
    TooltipModule,
  ],
  exports: [AngularFireModule, AngularFireAuthModule, AngularFireStorageModule],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthService,
    InternalPlayerService,
    FniApiService,
    StorageService,
    ScreenOrientation,
    StreamingMedia,
    VideoService,
    PresentationService,
    SlideNavService,
    VehiculesService,
    StateServiceService,
    LoadingIndicatorService,
    FirebaseAssetsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    SlideCanDeactivateGuard,
    PackageService,
    ResetHotspotService,
    NetworkService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
