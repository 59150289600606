import { FniApiService } from './fni-api.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { map, catchError } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PackageService {

  constructor(private logger: NGXLogger,
              private translate: TranslateService,
              private http: HttpClient,
              private api: FniApiService) {

  }


  public packageSelect(packageEnum: string, uuid: string, userEmail: string, userFirstName: string, userLastName: string) {
    const url: string = this.api.getPackageURL() + uuid;
    const postObj = {package: packageEnum, email: userEmail, first_name: userFirstName, last_name: userLastName};
    return this.http.post<any>( url, postObj ).pipe(
      map(data => {
        this.logger.info('PackageService.packageSelect() -> SUCCESS!  data: ' + JSON.stringify(data));
        return data;
      }),
      catchError( error => {
        return throwError(error);
      } )
    );
  }


}
