import { FniApiService } from "./fni-api.service";
import { InternalPlayerService } from "src/app/core/services/internal_player.service";
import { DealersService } from "src/app/core/services/dealers.service";
import { VehiculesService } from "./vehicules.service";
import { PresentationService } from "./presentation.service";
import { StorageService } from "./storage.service";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { NavController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class StateServiceService {
  constructor(
    private storageService: StorageService,
    public presentationService: PresentationService,
    private vehiculesService: VehiculesService,
    private logger: NGXLogger,
    private navCtrl: NavController
  ) {}

  public saveState(): void {
    this.logger.info("StateServiceService.saveState()-> state service is OFF!");
    return;

    const data: any = {
      sessionUUID: this.presentationService.sessionUUID,
      openSessionData: this.presentationService.openSessionData,
      presentationData: this.presentationService.presentationData,
      presentationSlides: this.presentationService.presentationSlides,
      vehicles: this.vehiculesService.vehicules,
      vehiculesCategories: this.vehiculesService.vehiculesCategories,
      years: this.vehiculesService.years,
      isWebsession: PresentationService.isWebsession,
      client: this.presentationService.client,
      makers: this.vehiculesService.makers,
      isMultiVehicules: this.vehiculesService.isMultiVehicules,
      isMultiDealer: DealersService.IS_MULTI_DEALERS,
      //   multiVehicules: this.vehiculesService.multiVehicules,
    };

    try {
      this.storageService.removeData(StorageService.STATE_DATA);
      //   this.logger.info(
      //     "StateServiceService.saveState()-> data character length: " +
      //       JSON.stringify(data).length
      //   );
      //
      this.storageService.setData(
        StorageService.STATE_DATA,
        JSON.stringify(data)
      );
    } catch (error) {
      this.logger.warn(
        "StateServiceService.saveState()-> LocalStorage quota exceeded. Cannot save state of the app!"
      );
    }

    // this.logger.info('StateServiceService.saveState()-> sessionUUID: ' + this.presentationService.sessionUUID);
    // this.logger.info('StateServiceService.saveState()-> locale: ' + this.presentationService.openSessionData.locale);
    // this.logger.info('StateServiceService.saveState()-> vehicules: ' + JSON.stringify(this.vehiculesService.vehicules));
  }

  public async gestoredState() {
    this.storageService.removeData(StorageService.STATE_DATA);
    this.storageService.removeData(StorageService.INTERESTS);
    return false;
    //
    const data: string = await this.storageService.getData(
      StorageService.STATE_DATA
    );
    const interests: any = await this.storageService.getData(
      StorageService.INTERESTS
    );
    // this.logger.info("StateServiceService.gestoredState() -> DATA! " + data);
    if (interests) {
      try {
        this.presentationService.interests = JSON.parse(interests);
      } catch (Error) {
        this.logger.info(
          "StateServiceService.gestoredState() -> ERROR: parsing interests"
        );
      }
    }

    if (data) {
      try {
        // ("StateServiceService.gestoredState() -> DATA! EXIST!");
        const parsedData: any = JSON.parse(data);
        // this.logger.info(
        //   "StateServiceService.gestoredState() -> parsedData: " +
        //     JSON.stringify(parsedData)
        // );
        this.presentationService.sessionUUID = parsedData.sessionUUID;
        this.presentationService.openSessionData = parsedData.openSessionData;
        this.presentationService.presentationData = parsedData.presentationData;
        if (this.presentationService.presentationData.dark) {
          document.body.classList.add("dark");
        }
        // this.logger.info(
        //   "StateServiceService.gestoredState() -> DARK: " +
        //     this.presentationService.presentationData.dark
        // );
        InternalPlayerService.appIsInternalPlayer =
          this.presentationService.openSessionData.appIsInternalPlayer;
        FniApiService.appIsInternalPlayer =
          this.presentationService.openSessionData.appIsInternalPlayer;
        //
        this.presentationService.presentationSlides =
          parsedData.presentationSlides;
        //
        this.presentationService.client = parsedData.client;

        this.vehiculesService.vehicules = parsedData.vehicles;
        this.vehiculesService.vehiculesCategories =
          parsedData.vehiculesCategories;
        this.vehiculesService.years = parsedData.years;
        this.vehiculesService.makers = parsedData.makers;
        // this.vehiculesService.multiVehicules = parsedData.multiVehicules;
        this.vehiculesService.isMultiVehicules = parsedData.isMultiVehicules;
        DealersService.IS_MULTI_DEALERS = parsedData.isMultiDealer;
        PresentationService.isWebsession = parsedData.isWebsession;
        // this.logger.info('StateServiceService.gestoredState()-> slides: ' + JSON.stringify(this.presentationService.presentationSlides));
        // this.logger.info('StateServiceService.gestoredState()-> vehicules: ' + JSON.stringify(this.vehiculesService.vehicules));
        // this.logger.info('StateServiceService.gestoredState()-> locale: ' + this.presentationService.openSessionData.locale);
        // this.logger.info('StateServiceService.gestoredState()-> years: ' + this.vehiculesService.years);
      } catch (Error) {
        this.logger.error(
          "StateServiceService.gestoredState() -> DATA EXIST BUT ERROR: " +
            Error
        );
        return false;
      }
      return true;
    } else {
      //   this.logger.info(
      //     "StateServiceService.gestoredState() -> NO DATA! are you suppose to have some????? "
      //   );
      //   this.logger.info(
      //     "StateServiceService.gestoredState() -> pathname: " +
      //       window.location.pathname
      //   );
      if (window.location.pathname !== "/login") {
        // this.logger.info("StateServiceService.gestoredState() -> NO LOGIN!");
        setTimeout(() => {
          this.navCtrl.navigateForward("login");
        }, 250);
        // this.navCtrl.navigateForward("login");
      }
      return false;
    }
    // return ;
  }

  public saveSlide(slideUUID: string): void {
    this.storageService.setData(StorageService.CURRENT_SLIDE, slideUUID);
    this.saveInterest();
  }

  public saveInterest(): void {
    this.storageService.setData(
      StorageService.INTERESTS,
      JSON.stringify(this.presentationService.interests)
    );
  }
}
