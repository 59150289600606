import { PresentationService } from "./../services/presentation.service";
import { AuthService } from "./../services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { NGXLogger } from "ngx-logger";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  static injector: Injector;

  // auth: AuthService;

  constructor(public inj: Injector, public logger: NGXLogger) {
    AuthInterceptor.injector = inj;
  }

  static injectHeaders(req: HttpRequest<any>): HttpRequest<any> {
    const auth = AuthInterceptor.injector.get(AuthService);
    const idToken = auth.getToken();
    // const translate = AuthInterceptor.injector.get(TranslateService);
    // const presentationService = AuthInterceptor.injector.get(PresentationService);
    const lang: string = PresentationService.locale;

    console.log("AuthInterceptor.injectHeaders() -> lang: " + lang);
    // console.log('AuthInterceptor.injectHeaders() -> idToken: ' + idToken);
    // console.log('AuthInterceptor.injectHeaders() -> translate: ' + translate);
    // console.log('AuthInterceptor.injectHeaders() -> req: ', req);

    if (idToken) {
      // console.log('AuthInterceptor.injectHeaders() -> idToken found: ' + idToken);
      // console.log('AuthInterceptor.injectHeaders() -> lang: ' + lang);

      if (lang) {
        // console.log("AuthInterceptor.injectHeaders() -> lang exist");
        return req.clone({
          headers: req.headers
            .set("Authorization", "Bearer " + idToken)
            .set("Accept-Language", lang),
        });
      }

      //   console.log("AuthInterceptor.injectHeaders() -> lang DOES NOT exist");
      return req.clone({
        headers: req.headers.set("Authorization", "Bearer " + idToken),
      });

      // return req.clone({
      //     headers: req.headers.set('Authorization', 'Bearer ' + idToken)
      //                         .set('Accept-Language', lang)
      // });
    } else {
      // console.log('AuthInterceptor.injectHeaders() -> NO TOKEN, only lang set in header: ' + lang);
      // if (lang) {
      //     return req.clone({
      //         headers: req.headers.set('Accept-Language', lang) // .set('Cache-Control', 'no-cache')
      //     });
      // }
      // else {
      //     return req;
      // }

      //   console.log("AuthInterceptor.injectHeaders() -> first ELSE");
      return req;
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const auth = this.inj.get(AuthService);
    const idToken = auth.getToken();
    const cloned = AuthInterceptor.injectHeaders(req);
    return next.handle(cloned);
  }
}
