import { TranslateService } from "@ngx-translate/core";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "summaryInterestEmpty",
})
export class SummaryInterestEmptyPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(interests: any[]): boolean {
    // console.log("SummaryInterestEmptyPipe.transform()");
    // console.log(interests);
    for (let i = 0; i < interests.length; i++) {
      if (interests[i] !== null && !interests[i].empty) {
        return false;
      }
    }
    return true;
  }
}
