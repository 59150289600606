import { InternalPlayerService } from "src/app/core/services/internal_player.service";
// import { InternalPlayerService } from "src/app/core/services/internal_player.service";
import { FNIEnums } from "./../enums/fni-enum";
import { environment } from "./../../../environments/environment";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";

@Injectable({
  providedIn: "root",
})
export class FniApiService {
  static appIsInternalPlayer: boolean = false;

  static LOGIN: string = "/v1/oauth/token";
  static FORGOT_PASSWORD: string = "/v1/password/forgot";
  static WEB_SESSION: string = "/v1/sessions/web-restore/";
  static SHARE: string = "/v1/presentations/${id}/share/";
  static PACKAGE: string = "/v1/sessions/package-select/";

  static TINTED_WINDOWS: string = "/v1/presentations/tint";

  static START_SESSION: string = "/v1/sessions";
  static SET_INTEREST: string = "/v1/sessions/";

  static CURRENT_PRESENTATION: string = "/v1/presentations/current";
  static PRESENTATION: string = "/v1/presentations/";
  static CURRENT_PRESENTATION_BRANCH: string =
    "/v1/presentations/current-branch";

  static CASH_PRESENTATION_BRANCH: string =
    "/v1/presentations/{ID}/cash-branch";
  static LEASE_PRESENTATION_BRANCH: string =
    "/v1/presentations/{ID}/lease-branch";
  static RETAIL_PRESENTATION_BRANCH: string =
    "/v1/presentations/{ID}/retail-branch";

  static CLIENT_SESSION: string = "/v1/client-sessions";
  static VEHICULES_FULL: string =
    "/v1/presentations/${ID}/cars/${YEAR}/maker/${MAKER_ID}";
  static VEHICULES: string = "/v1/presentations/${ID}/cars";
  static DEALERS: string = "/v1/user/dealers";
  // static VEHICULES: string = '../assets/data/vehicles3.json';

  domainPrefix: string;
  domainAPI: string;
  apiEnv: string;

  constructor(private logger: NGXLogger) {
    this.logger.info(
      "FniApiService.constructor() -> environment.apiEnv: " + environment.apiEnv
    );
    // tslint:disable-next-line: max-line-length
    this.domainAPI =
      environment.protocol +
      environment.domainPrefix +
      "." +
      (environment.apiEnv ? environment.apiEnv + "." : "") +
      environment.domain;
    this.logger.info(
      "FniApiService.constructor() -> domainAPI: " + this.domainAPI
    );
  }

  public versionApiCallURL(url: string): string {
    if (FniApiService.appIsInternalPlayer) {
      const newURL: string = url.replace("/v1/", "/v1-partner/");
      this.logger.info("versionApiCallURL() -> " + url + "  |  " + newURL);
      return newURL;
    }
    return url;
  }

  public getLoginURL(): string {
    return this.domainAPI + FniApiService.LOGIN; // Login api url must not be versionned
    // return this.domainAPI + this.versionApiCallURL(FniApiService.LOGIN);
  }

  public getForgotPasswordURL(): string {
    return (
      this.domainAPI + this.versionApiCallURL(FniApiService.FORGOT_PASSWORD)
    );
  }

  public webSessionURL(): string {
    return this.domainAPI + this.versionApiCallURL(FniApiService.WEB_SESSION);
  }

  public getShareURL(): string {
    return this.domainAPI + this.versionApiCallURL(FniApiService.SHARE);
  }

  public getPackageURL(): string {
    return this.domainAPI + this.versionApiCallURL(FniApiService.PACKAGE);
  }

  public getPresentationURL(): string {
    return (
      this.domainAPI +
      this.versionApiCallURL(FniApiService.CURRENT_PRESENTATION)
    );
  }

  public getPresentationByIDURL(id: string): string {
    return (
      this.domainAPI + this.versionApiCallURL(FniApiService.PRESENTATION + id)
    );
  }

  public getStartSessionURL(): string {
    return this.domainAPI + this.versionApiCallURL(FniApiService.START_SESSION);
  }

  public getInterestURL(): string {
    return this.domainAPI + this.versionApiCallURL(FniApiService.SET_INTEREST);
  }

  public getPresentationBranchURL(
    branch: string,
    dealerPresentationID: string
  ): string {
    let branchURL: string;
    if (branch === FNIEnums.OP_CASH) {
      branchURL = FniApiService.CASH_PRESENTATION_BRANCH;
    } else if (branch === FNIEnums.OP_LEASE) {
      branchURL = FniApiService.LEASE_PRESENTATION_BRANCH;
    } else if (branch === FNIEnums.OP_RETAIL) {
      branchURL = FniApiService.RETAIL_PRESENTATION_BRANCH;
    }
    branchURL = branchURL.replace("{ID}", dealerPresentationID);
    console.log(">>> dealerPresentationID: " + dealerPresentationID);
    // console.log('>>> branchURL: ' + branchURL);
    return this.domainAPI + this.versionApiCallURL(branchURL); // FniApiService.CURRENT_PRESENTATION_BRANCH;
  }

  public getClientSessionURL(): string {
    return (
      this.domainAPI + this.versionApiCallURL(FniApiService.CLIENT_SESSION)
    );
  }

  public getVehiculesURL(): string {
    return this.domainAPI + this.versionApiCallURL(FniApiService.VEHICULES);
  }

  public getVehiculesFullURL(): string {
    return (
      this.domainAPI + this.versionApiCallURL(FniApiService.VEHICULES_FULL)
    );
  }

  public getDealersURL(): string {
    return this.domainAPI + this.versionApiCallURL(FniApiService.DEALERS);
  }
}
