import { PresentationService } from "./presentation.service";
import { LoginPartnerModel, LoginPartnerEnum } from "./../models/login-model";
import { AuthService } from "./auth.service";
import { LoadingIndicatorService } from "./loading-indicator.service";
import { TranslateService } from "@ngx-translate/core";
import { AlertController } from "@ionic/angular";
import { FniApiService } from "./fni-api.service";
import { environment } from "./../../../environments/environment.local";
import { InternalPlayerEnum } from "./../enums/fni-enum";
import { BehaviorSubject } from "rxjs";
import { NGXLogger } from "ngx-logger";
import { HostListener, Injectable } from "@angular/core";
import { JSDocTagName } from "@angular/compiler/src/output/output_ast";
import { LoginPageModule } from "src/app/pages/login/login.module";
import { isUndefined } from "util";

@Injectable({
  providedIn: "root",
})
export class InternalPlayerService {
  static appIsInternalPlayer: boolean = false;

  private _appReady = new BehaviorSubject<any>(null);
  private _appLoginSuccess = new BehaviorSubject<boolean>(false);

  playerReady = this._appReady.asObservable();
  loginSuccess = this._appLoginSuccess.asObservable();
  isReady: boolean = false;
  initDataReceived: boolean = false;

  domain: string = "https://admin.fni.test";
  shareInfo = {
    client_firstname: "",
    client_lastname: "",
    client_email: ""
  };

  constructor(
    private logger: NGXLogger,
    public fniApi: FniApiService,
    private alertController: AlertController,
    private translate: TranslateService,
    private loadingIndicatorService: LoadingIndicatorService,
    private auth: AuthService,
    public presentationService: PresentationService
  ) {
    this.domain = environment.protocol + environment.domain;
    // this.logger.info(
    //   "InternalPlayerService.constructor() -> domain: " + this.domain
    // );
  }

  public setPlayerReady(ready: boolean): void {
    this.logger.info(
      "InternalPlayerService.setPlayerReady() -> ready: " + ready
    );
    this.isReady = ready;
    InternalPlayerService.appIsInternalPlayer = ready;
    FniApiService.appIsInternalPlayer = ready;
    this.presentationService.openSessionData.appIsInternalPlayer = ready;
    this._appReady.next(this.isReady);
  }

  public setHostURL(host: string): void {
    this.domain = host;
    this.logger.info(
      "InternalPlayerService.setHostURL() -> domain: " + this.domain
    );
  }

  public setApiURL(api: string): void {
    this.logger.info("InternalPlayerService.setApiURL() -> api: " + api);
    this.fniApi.domainAPI = api;
  }

  public onSendPostMessage(name: string, data: any = null): void {
    this.logger.info(
      "InternalPlayerService.onSendPostMessage() -> name: " + name
    );
    this.logger.info(
      "InternalPlayerService.onSendPostMessage() -> this.domain: " + this.domain
    );

    let newData = null;
    if (data === null) {
      newData = {
        external_session_data:
          this.presentationService.openSessionData.external_session_data,
      };
    } else {
      data.external_session_data =
        this.presentationService.openSessionData.external_session_data;
    }
    window.top.postMessage(
      {
        // name: "A message sent from Angular in a JSON/JS data structure",
        name: name,
        data: newData,
      },
      this.domain
    );
  }

  //   @HostListener("window:message", ["$event"])
  onMessage(event: MessageEvent) {
    if (
      event.data &&
      event.data.type &&
      (event.data.type === "webpackOk" || event.data.type === "webpackInvalid")
    ) {
      return;
    }
    //
    if (event.type && event.type === "message") {
      console.log("ANGULAR onMessage()");

      console.log("ANGULAR Message received type: " + event.type);
      console.log("ANGULAR Message received from: " + event.origin);
      //   console.log("ANGULAR Message: " + event.data);
      //   console.log("ANGULAR Message.name: " + event.data.name);
      //   console.log("ANGULAR Message.data: " + event.data.data);
      // console.log("Message JSON parse: " + JSON.parse(event.data));
      this.logger.info("ANGULAR DATA RECEIVED: " + JSON.stringify(event.data));
      console.log("---- END ----");

      if (event.data.name === InternalPlayerEnum.FNIP_SET_PLAYER_INIT_DATA) {
        this.handlePlayerInitData(event);
      }
      //   else if() {

      //   }
    }
  }

  public handlePlayerInitData(event: any): void {
    this.initDataReceived = true;
    console.log("handlePlayerInitData() -> IS FNIP_SET_PLAYER_INIT_DATA!!!");
    console.log(event.data);
    console.log(
      "handlePlayerInitData() -> client_id: " + event.data.data.client_id
    );
    console.log(
      "handlePlayerInitData() -> client_secret: " +
        event.data.data.client_secret
    );
    console.log("handlePlayerInitData() -> locale: " + event.data.data.locale);
    console.log(
      "handlePlayerInitData() -> external_session_data: " +
        JSON.stringify(event.data.data.external_session_data)
    );

    console.log(
      "handlePlayerInitData() -> client_email: " + event.data.data.client_email
    );
    console.log(
      "handlePlayerInitData() -> client_firstname: " +
        event.data.data.client_firstname
    );
    console.log(
      "handlePlayerInitData() -> client_lastname: " +
        event.data.data.client_lastname
    );
    
    
    //
    if (!event.data.data.client_id) {
      this.showErrorMessage("La propriété client_id est null ou inexistante!");
    }
    //
    if (!event.data.data.client_secret) {
      this.showErrorMessage(
        "La propriété client_secret est null ou inexistante!"
      );
    }
    //
    if (!event.data.data.locale) {
      this.showErrorMessage("La propriété locale est null ou inexistante!");
    } else if (
      event.data.data.locale !== "fr-CA" &&
      event.data.data.locale !== "en-CA"
    ) {
      this.showErrorMessage(
        "La propriété locale existe mais n'est ni fr-CA ou en-CA (les locales supportées par cette app)! La valeur reçu: " +
          event.data.data.locale
      );
    } else {
      this.translate.use(event.data.data.locale);
      this.presentationService.openSessionData.locale = event.data.data.locale;
      PresentationService.locale = event.data.data.locale;
    }
    //
    if (!event.data.data.external_session_data) {
      this.showErrorMessage(
        "La propriété external_session_data est null ou inexistante!"
      );
    } else {
      this.presentationService.openSessionData.external_session_data =
        event.data.data.external_session_data;
    }

    event.data.data;
    if (event.data.data.client_firstname) {
      this.shareInfo.client_firstname = event.data.data.client_firstname;
    }
    if (event.data.data.client_lastname) {
      this.shareInfo.client_lastname = event.data.data.client_lastname;
    }
    if (event.data.data.client_email) {
      this.shareInfo.client_email = event.data.data.client_email;
    }
    //
    //
    //
    //
    // --o LOGIN PARTNER
    const logModel: LoginPartnerModel = {
      client_id: event.data.data.client_id,
      client_secret: event.data.data.client_secret,
      grant_type: LoginPartnerEnum.GRANT_TYPE,
      //   scope: LoginPartnerEnum.SCOPE,
    };

    // this.loadingIndicatorService.presentLoading();
    this.auth.login(logModel).subscribe(
      (response) => {
        // this.loadCurrentPresentation();
        this.logger.info(
          "handlePlayerInitData() LOGIN PARTNER success: " +
            JSON.stringify(response)
        );
        this._appLoginSuccess.next(true);
        this.onSendPostMessage(
          InternalPlayerEnum.FNIP_ON_DEALER_USER_AUTHENTICATED
        );
        this.loadingIndicatorService.dismissLoading(
          "handlePlayerInitData() LOGIN PARTNER success"
        );
      },
      (error) => {
        // TODO: handle errors
        this.logger.error(
          "InternalPlayerService.handlePlayerInitData() -> ERROR: " +
            JSON.stringify(error)
        );
        //
        this.showErrorMessage(error);
        // if (
        //   error.status === 400 ||
        //   error.status === 401 ||
        //   error.status === 403
        // ) {
        //   this.presentLoadingErrorAlert();
        // }
      }
    );
  }

  public async showErrorMessage(message: string) {
    console.log("showErrorMessage()");
    let alert = await this.alertController.create({
      header: this.translate.instant("COMMON.ERROR_DIALOG_TITLE"),
      message: message,
      backdropDismiss: true,
      buttons: [
        {
          text: "OK",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            // console.log('Update Canceled');
            alert = null;
          },
        },
      ],
    });
    await alert.present();
  }
}
