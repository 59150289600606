import { DealersService } from './dealers.service';
import { AlertController, NavController, Platform } from "@ionic/angular";
import { FniApiService } from "./fni-api.service";
import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { HttpClient } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { throwError, BehaviorSubject } from "rxjs";
import { VehiculeModel } from "./vehicules.service";
import { ColorModel } from "src/app/pages/color-select/color-select.page";

@Injectable({
  providedIn: "root",
})
export class PresentationService {
  static isWebsession: boolean = false;
  static locale: string = "fr-CA";
  static isMobile: boolean = false;
  //
  darkMode: boolean = false;
  selectedDealer: any;
  singleDealer: any;

  openSessionData: OpenSessionModel = {
    locale: null,
    operation: null,
    year: null,
    model: null,
    color: null,
    currentSlide: null,
    interest: null,
    presentation_id: null,
    maker: null,
    external_session_data: null,
    appIsInternalPlayer: false,
    dealer: null,
    amf_accept: false,
    amf_email: null
  };

  interests: any = {};

  client: any;
  sessionUUID: string;
  sessionID: number;
  // isWebsession: boolean = false;

  private dataSource = new BehaviorSubject<PresentationModel>(null);
  onPresentationData = this.dataSource.asObservable();

  presentationData: PresentationModel;
  presentationSlides: SlideModel[];

  constructor(
    private logger: NGXLogger,
    private translate: TranslateService,
    private http: HttpClient,
    // private navService: SlideNavService,
    private api: FniApiService,
    public alertController: AlertController,
    private platform: Platform,
    private navCtrl: NavController
  ) // private dealersService: DealersService
  {}

  public injectSlideAt(slideData: any, slidePosition: number): void {
    // this.logger.info(
    //   "PresentationService.injectSlideAt() -> slideData: " +
    //     JSON.stringify(slideData) +
    //     " at position " +
    //     slidePosition
    // );
    // this.logger.info(
    //   "PresentationService.injectSlideAt() -> this.presentationSlides: " +
    //     JSON.stringify(this.presentationSlides)
    // );
    // this.presentationSlides.splice(slidePosition, 0, slideData);
    // this.logger.info(
    //   "PresentationService.injectSlideAt() -> this.presentationSlides: " +
    //     JSON.stringify(this.presentationSlides)
    // );
  }

  public share(userEmail: string, userFirstName: string, userLastName: string) {
    const url: string =
      this.api.getShareURL().replace("${id}", this.presentationData.id) +
      this.sessionUUID;
    return this.http
      .post<any>(url, {
        email: userEmail,
        first_name: userFirstName,
        last_name: userLastName,
      })
      .pipe(
        map((data) => {
          // this.logger.info('PresentationService.loadPresentation() -> SUCCESS!');
          this.logger.info(
            "PresentationService.share() -> SUCCESS!  data: " +
              JSON.stringify(data)
          );
          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public startSession(): any {
    const url: string = this.api.getStartSessionURL();
    this.openSessionData.presentation_id = this.presentationData.id;
    // this.openSessionData.color.id = "1";
    this.openSessionData.color.id = this.openSessionData.color.id;
    this.openSessionData.dealer = this.selectedDealer;
    this.logger.info(
      "startSession() DEALER WATCH: " + JSON.stringify(this.selectedDealer)
    );

    this.logger.info(
      "PresentationService.startSession() -> this.openSessionData: " +
        JSON.stringify(this.openSessionData)
    );
    return this.http.post<any>(url, this.openSessionData).pipe(
      map((data) => {
        this.logger.info(
          "PresentationService.startSession() -> SUCCESS!  data: " +
            JSON.stringify(data)
        );
        this.sessionID = data.data.id;
        this.sessionUUID = data.data.uuid;
        this.logger.info(
          "PresentationService.startSession() -> sessionUUID: " +
            this.sessionUUID
        );
        return data;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public resetInterest(): void {
    this.interests = {};
    this.logger.info('VehicleWarrantyPage.resetInterest() -> interests: ' + JSON.stringify(this.interests));
  }
  
  public setInterest(sessionData: any, title: string): void {
    // this.logger.info('VehicleWarrantyPage.onInterestSelected() -> title: ' + title);
    this.interests[sessionData.slide_uuid] = {
      color: sessionData.action.selected,
      slideTitle: title,
    };

    this.logger.info(
      "VehicleWarrantyPage.onInterestSelected() -> this.openSessionData.interests: " +
        JSON.stringify(this.interests)
    );
    this.updateSesssion(sessionData).subscribe(
      (response) => {
        this.logger.info(
          "PresentationService.setInterest() -> SUCCESS!  data: " +
            JSON.stringify(response)
        );
      },
      (error) => {
        this.logger.error(
          "PresentationService.setInterest() -> ERROR!  data: " +
            JSON.stringify(error)
        );
      }
    );
  }

  public updateSesssion(sessionData: any): any {
    const url: string = this.api.getInterestURL() + this.sessionUUID;
    this.logger.info(
      "PresentationService.updateSesssion() -> sessionData: " +
        JSON.stringify(sessionData)
    );
    return this.http.post<any>(url, sessionData).pipe(
      map((data) => {
        // this.logger.info('PresentationService.updateSesssion() -> SUCCESS!  data: ' + JSON.stringify(data));
        return data;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getPresentationSlides(): SlideModel[] {
    return this.presentationSlides;
  }

  public loadPresentation() {
    const url: string = this.api.getPresentationURL();
    return this.http.get<any>(url).pipe(
      map((data) => {
        // this.logger.info('PresentationService.loadPresentation() -> SUCCESS!');
        this.logger.info(
          "PresentationService.loadPresentation() -> SUCCESS!  data: " +
            JSON.stringify(data.data)
        );
        this.logger.info(
          "PresentationService.loadPresentation() -> SUCCESS!  data.slides: " +
            JSON.stringify(data.data.slides)
        );
        this.presentationData = data.data;
        this.presentationSlides = this.presentationData.slides;
        this.selectedDealer = this.presentationData.dealer; 
        // this.darkMode = this.presentationData.dark;
        if (this.presentationData.dark) {
          document.body.classList.add("dark");
        }

        if (!this.presentationData.is_mobile_ready) {
          const isNotSupported =
            (this.platform.is("ios") || this.platform.is("android")) &&
            (!this.platform.is("ipad") || !this.platform.is("tablet"));
          this.logger.info(
            "PresentationService.loadPresentation() -> is NOT  SUPPORTED: " +
              isNotSupported
          );
          if (isNotSupported) {
            setTimeout(() => {
              //   this.navCtrl.navigateRoot("/not-supported");
            }, 500);
          } else {
          }
        }
        // this.logger.info(
        //   "PresentationService.loadPresentationByID() -> SUCCESS!  data: " +
        //     this.presentationData.dark
        // );
        // this.logger.info(
        //   "PresentationService.loadPresentation() -> SUCCESS!  data: " +
        //     JSON.stringify(this.presentationData.lang)
        // );

        this.openSessionData.maker = this.presentationData.dealer.car_make.slug;
        this.dataSource.next(this.presentationData);
        return data;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public loadPresentationByID(id: string) {
    const url: string = this.api.getPresentationByIDURL(id);
    return this.http.get<any>(url).pipe(
      map((data) => {
        this.logger.info('PresentationService.loadPresentationByID() -> SUCCESS!');
        // this.logger.info('PresentationService.loadPresentationByID() -> SUCCESS!  data: ' + JSON.stringify(data.data));
        this.presentationData = data.data;
        this.presentationSlides = this.presentationData.slides;
        this.selectedDealer = this.presentationData.dealer; 
        // this.presentationData.dark = true;
        if (this.presentationData.dark) {
          document.body.classList.add("dark");
        }
        // this.darkMode = this.presentationData.dark;
        // this.logger.info(
        //   "PresentationService.loadPresentationByID() -> SUCCESS!  data: " +
        //     this.presentationData.dark
        // );
        //
        if (!this.presentationData.is_mobile_ready) {
          const isNotSupported =
            (this.platform.is("ios") || this.platform.is("android")) &&
            (!this.platform.is("ipad") || !this.platform.is("tablet"));
          this.logger.info(
            "PresentationService.loadPresentation() -> is NOT  SUPPORTED: " +
              isNotSupported
          );
          if (isNotSupported) {
            setTimeout(() => {
              //   this.navCtrl.navigateRoot("/not-supported");
            }, 500);
          } else {
          }
        }
        // this.logger.info(
        //   "PresentationService.loadPresentationByID() -> SUCCESS!  data: " +
        //     JSON.stringify(this.presentationData.lang)
        // );
        // this.presentationData = null;
        // this.logger.info(
        //   "PresentationService.loadPresentationByID() -> " +
        //     this.presentationData
        // );
        if (!this.presentationData) {
          //   this.showErrorMessage(this.translate.instant("ERRORS.NO_PRESENTION"));
        }

        this.openSessionData.maker = this.presentationData.dealer.car_make.slug;
        this.dataSource.next(this.presentationData);
        return data;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public loadPresentationBranch() {
    this.logger.info("PresentationService.loadPresentationBranch()");

    // this.logger.info(
    //   "PresentationService.loadPresentationBranch() -----> " +
    //     JSON.stringify(this.presentationData.id)
    // );

    const dealerPresentationID = this.presentationData.id;
    this.logger.info(
      "PresentationService.loadPresentationBranch() => dealerPresentationID: " +
        dealerPresentationID
    );

    const url: string = this.api.getPresentationBranchURL(
      this.openSessionData.operation,
      dealerPresentationID
    );
    //
    return this.http.get<any>(url).pipe(
      map((data) => {
        // this.logger.info('PresentationService.loadPresentationBranch() -> SUCCESS!');
        this.logger.info(
          "PresentationService.loadPresentationBranch() -> SUCCESS! " +
            this.presentationData
        );
        this.presentationSlides = this.presentationData.slides.concat(
          data.data.slides
        );
        if (!data.data) {
          //   this.showErrorMessage(this.translate.instant("ERRORS.NO_PRESENTION"));
        }
        // this.logger.info('*** SLIDES: ' + JSON.stringify(this.presentationSlides));
        // this.logger.info('*** SLIDES LENGTH: ' + this.presentationSlides.length);
        this.dataSource.next(this.presentationData);
        return data;
      }),
      catchError((error) => {
        this.logger.info(
          "PresentationService.loadPresentationBranch() -> error: " +
            JSON.stringify(error)
        );
        return throwError(error);
      })
    );
  }

  public initBranch(): void {
    this.dataSource.next(this.presentationData);
  }

  //
  public async showErrorMessage(message: string) {
    console.log("showErrorMessage()");
    let alert = await this.alertController.create({
      header: this.translate.instant("COMMON.ERROR_DIALOG_TITLE"),
      message: message,
      backdropDismiss: true,
      buttons: [
        {
          text: "OK",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            // console.log('Update Canceled');
            alert = null;
          },
        },
      ],
    });
    await alert.present();
  }
}

export interface PresentationModel {
  id: string;
  name: string;
  name_local: any;
  dealer: any;
  slides: SlideModel[];
  assets: any;
  tint: any;
  tint_generic: any;
  lang: any;
  dark: boolean;
  is_mobile_ready: boolean;
}

export interface SlideModel {
  uuid: string;
  type: string;
  sectionStart: boolean;
  title: string;
  title_summary_local: any;
  title_local: any;
  content: any;
  nav_type: string;
  is_essential: boolean;
}

export interface OpenSessionModel {
  locale: string;
  operation: string;
  year: string;
  model: VehiculeModel;
  color: ColorModel;
  currentSlide: string; // uuid de la slide que je viens de voir
  interest: string; // ENUM: RED, YELLOW, GREEN    or    NOT_INTERESTED, KNOW_MORE, INTERESTED // TODO: IS THIS IN USED ANYMORE?
  presentation_id: string;
  maker: string;
  external_session_data: ExternalSessionDataModel;
  appIsInternalPlayer: boolean;
  dealer: any;
  amf_accept: boolean;
  amf_email: string;
}

export interface ExternalSessionDataModel {
  uuid: string;
}

export const DEALERS_SLIDE: any = {
  object: "Slide",
  uuid: "no-uuid-DEALERS_SLIDE",
  type: "P_DEALERS_SLIDE",
  title: "CHOOSE YOUR DEALER / CHOISIR VOTRE CONCESSIONNAIRE",
  title_local: {
    title_fr_CA: "CHOOSE YOUR DEALER / CHOISIR VOTRE CONCESSIONNAIRE",
    title_en_CA: "CHOOSE YOUR DEALER / CHOISIR VOTRE CONCESSIONNAIRE",
  },
  content: {
    sectionStart: false,
  },
  presentation_branch: "MASTER",
  nav_type: "NO_NAV",
  position: 0,
  is_essential: false,
  debug: {
    id: 1,
    created_at: "2022-02-09T21:06:34.000000Z",
    updated_at: "2022-02-09T21:06:34.000000Z",
    deleted_at: null,
  },
};
