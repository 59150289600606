import { SLinesPointsTwoColumnsPage } from "./pages/s-lines-points-two-columns/s-lines-points-two-columns.page";
import { SlideCanDeactivateGuard } from "./core/guards/slide-can-deactivate-guard.service";
import { HomePage } from "./home/home.page";
import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "not-supported",
    loadChildren: () =>
      import("./pages/not-supported/not-supported.module").then(
        (m) => m.NotSupportedPageModule
      ),
  },
  {
    path: "page-not-found",
    loadChildren: () =>
      import("./pages/page-not-found/page-not-found.module").then(
        (m) => m.PageNotFoundPageModule
      ),
  },
  {
    path: ":home",
    component: HomePage,
    children: [
      // SUPER IMPORTANT
      {
        path: "",
        loadChildren: () =>
          import("./home/home.module").then((m) => m.HomePageModule),
      },
      {
        path: "web-login",
        loadChildren: () =>
          import("./pages/web-login/web-login.module").then(
            (m) => m.WebLoginPageModule
          ),
        canDeactivate: [SlideCanDeactivateGuard],
      },
      {
        path: "dealers",
        loadChildren: () =>
          import("./pages/dealer-select/dealer-select-routing.module").then(
            (m) => m.DealerSelectPageRoutingModule
          ),
        canDeactivate: [SlideCanDeactivateGuard],
      },
      {
        path: "amf",
        loadChildren: () =>
          import("./pages/amf/amf-routing.module").then(
            (m) => m.AmfPageRoutingModule
          ),
        canDeactivate: [SlideCanDeactivateGuard],
      },
      {
        path: "language",
        loadChildren: () =>
          import("./pages/locale-select/locale-select.module").then(
            (m) => m.LocaleSelectPageModule
          ),
        canDeactivate: [SlideCanDeactivateGuard],
      },
      {
        path: "language/:id",
        loadChildren: () =>
          import("./pages/locale-select/locale-select.module").then(
            (m) => m.LocaleSelectPageModule
          ),
        canDeactivate: [SlideCanDeactivateGuard],
      },
      {
        path: "operation",
        loadChildren: () =>
          import("./pages/operation-select/operation-select.module").then(
            (m) => m.OperationSelectPageModule
          ),
        canDeactivate: [SlideCanDeactivateGuard],
      },
      {
        path: "operation/:id",
        loadChildren: () =>
          import("./pages/operation-select/operation-select.module").then(
            (m) => m.OperationSelectPageModule
          ),
        canDeactivate: [SlideCanDeactivateGuard],
      },
      {
        path: "year",
        loadChildren: () =>
          import("./pages/year-select/year-select.module").then(
            (m) => m.YearSelectPageModule
          ),
        canDeactivate: [SlideCanDeactivateGuard],
      },
      {
        path: "year/:id",
        loadChildren: () =>
          import("./pages/year-select/year-select.module").then(
            (m) => m.YearSelectPageModule
          ),
        canDeactivate: [SlideCanDeactivateGuard],
      },
      {
        path: "maker",
        loadChildren: () =>
          import("./pages/maker-select/maker-select.module").then(
            (m) => m.MakerSelectPageModule
          ),
        canDeactivate: [SlideCanDeactivateGuard],
      },
      {
        path: "maker:id",
        loadChildren: () =>
          import("./pages/maker-select/maker-select.module").then(
            (m) => m.MakerSelectPageModule
          ),
        canDeactivate: [SlideCanDeactivateGuard],
      },
      {
        path: "video",
        loadChildren: () =>
          import("./pages/video-player/video-player.module").then(
            (m) => m.VideoPlayerPageModule
          ),
        canDeactivate: [SlideCanDeactivateGuard],
      },
      {
        path: "video/:id",
        loadChildren: () =>
          import("./pages/video-player/video-player.module").then(
            (m) => m.VideoPlayerPageModule
          ),
        canDeactivate: [SlideCanDeactivateGuard],
      },
      {
        path: "model",
        loadChildren: () =>
          import("./pages/model-select/model-select.module").then(
            (m) => m.ModelSelectPageModule
          ),
        canDeactivate: [SlideCanDeactivateGuard],
      },
      {
        path: "model/:id",
        loadChildren: () =>
          import("./pages/model-select/model-select.module").then(
            (m) => m.ModelSelectPageModule
          ),
        canDeactivate: [SlideCanDeactivateGuard],
      },
      {
        path: "color",
        loadChildren: () =>
          import("./pages/color-select/color-select.module").then(
            (m) => m.ColorSelectPageModule
          ),
        canDeactivate: [SlideCanDeactivateGuard],
      },
      {
        path: "color/:id",
        loadChildren: () =>
          import("./pages/color-select/color-select.module").then(
            (m) => m.ColorSelectPageModule
          ),
        canDeactivate: [SlideCanDeactivateGuard],
      },
      // {
      //   path: 'lease-protection',
      //   loadChildren: () => import('./pages/lease-protection/lease-protection.module').then( m => m.LeaseProtectionPageModule)
      // },
      {
        path: "info-points",
        loadChildren: () =>
          import("./pages/vehicle-warranty/vehicle-warranty.module").then(
            (m) => m.VehicleWarrantyPageModule
          ),
      },
      {
        path: "info-points/:id",
        loadChildren: () =>
          import("./pages/vehicle-warranty/vehicle-warranty.module").then(
            (m) => m.VehicleWarrantyPageModule
          ),
      },
      {
        path: "header-info-footer",
        // tslint:disable-next-line: max-line-length
        loadChildren: () =>
          import(
            "./pages/insurance-replacement/insurance-replacement.module"
          ).then((m) => m.InsuranceReplacementPageModule),
      },
      {
        path: "header-info-footer/:id",
        // tslint:disable-next-line: max-line-length
        loadChildren: () =>
          import(
            "./pages/insurance-replacement/insurance-replacement.module"
          ).then((m) => m.InsuranceReplacementPageModule),
      },
      {
        path: "customize-vehicle",
        loadChildren: () =>
          import("./pages/customize-vehicle/customize-vehicle.module").then(
            (m) => m.CustomizeVehiclePageModule
          ),
      },
      {
        path: "customize-vehicle/:id",
        loadChildren: () =>
          import("./pages/customize-vehicle/customize-vehicle.module").then(
            (m) => m.CustomizeVehiclePageModule
          ),
      },
      {
        path: "coverage-video",
        loadChildren: () =>
          import("./pages/coverage-video/coverage-video.module").then(
            (m) => m.CoverageVideoPageModule
          ),
      },
      {
        path: "coverage-video/:id",
        loadChildren: () =>
          import("./pages/coverage-video/coverage-video.module").then(
            (m) => m.CoverageVideoPageModule
          ),
      },
      {
        path: "slim-image-text",
        loadChildren: () =>
          import("./pages/slim-image-text/slim-image-text.module").then(
            (m) => m.SlimImageTextPageModule
          ),
      },
      {
        path: "slim-image-text/:id",
        loadChildren: () =>
          import("./pages/slim-image-text/slim-image-text.module").then(
            (m) => m.SlimImageTextPageModule
          ),
      },
      {
        path: "checkboxes-media",
        loadChildren: () =>
          import("./pages/checkboxes-media/checkboxes-media.module").then(
            (m) => m.CheckboxesMediaPageModule
          ),
      },
      {
        path: "checkboxes-media/:id",
        loadChildren: () =>
          import("./pages/checkboxes-media/checkboxes-media.module").then(
            (m) => m.CheckboxesMediaPageModule
          ),
      },
      {
        path: "start",
        loadChildren: () =>
          import("./pages/start/start.module").then((m) => m.StartPageModule),
      },
      {
        path: "start/:id",
        loadChildren: () =>
          import("./pages/start/start.module").then((m) => m.StartPageModule),
      },
      {
        path: "summary",
        loadChildren: () =>
          import("./pages/summary/summary.module").then(
            (m) => m.SummaryPageModule
          ),
      },
      {
        path: "summary/:id",
        loadChildren: () =>
          import("./pages/summary/summary.module").then(
            (m) => m.SummaryPageModule
          ),
      },
      {
        path: "thank-you",
        loadChildren: () =>
          import("./pages/end-page/end-page.module").then(
            (m) => m.EndPagePageModule
          ),
      },
      {
        path: "tinted-windows",
        loadChildren: () =>
          import("./pages/tinted-windows/tinted-windows.module").then(
            (m) => m.TintedWindowsPageModule
          ),
      },
      {
        path: "tinted-windows/:id",
        loadChildren: () =>
          import("./pages/tinted-windows/tinted-windows.module").then(
            (m) => m.TintedWindowsPageModule
          ),
      },
      {
        path: "img-img",
        loadChildren: () =>
          import("./pages/s-img-img/s-img-img.module").then(
            (m) => m.SImgImgPageModule
          ),
      },
      {
        path: "img-img/:id",
        loadChildren: () =>
          import("./pages/s-img-img/s-img-img.module").then(
            (m) => m.SImgImgPageModule
          ),
      },
      {
        path: "img-img-next",
        loadChildren: () =>
          import("./pages/s-img-img-next/s-img-img-next.module").then(
            (m) => m.SImgImgNextPageModule
          ),
      },
      {
        path: "img-img-next/:id",
        loadChildren: () =>
          import("./pages/s-img-img-next/s-img-img-next.module").then(
            (m) => m.SImgImgNextPageModule
          ),
      },

      {
        path: "points-2-columns",
        loadChildren: () =>
          import(
            "./pages/s-lines-points-two-columns/s-lines-points-two-columns.module"
          ).then((m) => m.SLinesPointsTwoColumnsPageModule),
      },
      {
        path: "points-2-columns/:id",
        loadChildren: () =>
          import(
            "./pages/s-lines-points-two-columns/s-lines-points-two-columns.module"
          ).then((m) => m.SLinesPointsTwoColumnsPageModule),
      },

      {
        path: "points-3-columns",
        loadChildren: () =>
          import(
            "./pages/s-lines-points-three-columns/s-lines-points-three-columns.module"
          ).then((m) => m.SLinesPointsThreeColumnsPageModule),
      },
      {
        path: "points-3-columns/:id",
        loadChildren: () =>
          import(
            "./pages/s-lines-points-three-columns/s-lines-points-three-columns.module"
          ).then((m) => m.SLinesPointsThreeColumnsPageModule),
      },
    ],
  },
  {
    path: "dealer-select",
    loadChildren: () =>
      import("./pages/dealer-select/dealer-select.module").then(
        (m) => m.DealerSelectPageModule
      ),
  },
  {
    path: "s-lines-points-two-columns",
    loadChildren: () =>
      import(
        "./pages/s-lines-points-two-columns/s-lines-points-two-columns.module"
      ).then((m) => m.SLinesPointsTwoColumnsPageModule),
  },
  {
    path: "s-lines-points-three-columns",
    loadChildren: () =>
      import(
        "./pages/s-lines-points-three-columns/s-lines-points-three-columns.module"
      ).then((m) => m.SLinesPointsThreeColumnsPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      enableTracing: false,
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
