import { InternalPlayerService } from "src/app/core/services/internal_player.service";
import { DealersService } from "src/app/core/services/dealers.service";
import { Router } from "@angular/router";
import { StateServiceService } from "./../services/state-service.service";
import { StorageService } from "./storage.service";
import { BehaviorSubject } from "rxjs";
import { FNIEnums, PagesEnums } from "./../enums/fni-enum";
import { PresentationService, SlideModel } from "./presentation.service";
import { FniApiService } from "./fni-api.service";
import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { HttpClient } from "@angular/common/http";
import { NavController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class SlideNavService {
  private dataSource = new BehaviorSubject<SlideModel>(null);
  onSlideReady = this.dataSource.asObservable();

  currentSlide: SlideModel;
  currentSlideIndex: number = 0;
  backOrForwardClick: boolean = false;

  constructor(
    private logger: NGXLogger,
    private translate: TranslateService,
    public presentationService: PresentationService,
    private storageService: StorageService,
    private stateService: StateServiceService,
    private navCtrl: NavController,
    private router: Router
  ) {
    
  }

  public start(): void {
    console.log(
      "SlideNavService.start() !!!!! -> " +
        InternalPlayerService.appIsInternalPlayer
    );
    if (this.skipSlide(0)) {
      console.log("we DO skipSlide()");
      this.setSlide(1);
    } else {
      console.log("DO NOT skipSlide()");
      this.setSlide(0);
    }
    //
    this.dataSource.next(this.currentSlide);
  }

  public skipSlide(slideIndex: number): boolean {
    console.log(
      "skipSlide() -> appIsInternalPlayer: " +
        InternalPlayerService.appIsInternalPlayer
    );
    if (InternalPlayerService.appIsInternalPlayer) {
      const slide =
        this.presentationService.getPresentationSlides()[slideIndex];
      console.log(slide);
      console.log(slide.type);
      console.log(slide.type === PagesEnums.P_LOCAL_SELECT);
      if (slide && slide.type === PagesEnums.P_LOCAL_SELECT) {
        console.log("NEXT SLIDE IS P_LOCAL_SELECT");
        return true;
      }
    }
    return false;
  }

  public recover(slideUUID: string): boolean {
    // console.log('... ' + slideUUID);
    const index: number = this.presentationService
      .getPresentationSlides()
      .findIndex((item) => {
        // console.log('... ' + item.uuid + ' / ' + slideUUID);
        return item.uuid === slideUUID;
      });

    // this.logger.info('SlideNavService.recover() -> index: ' + index);
    if (index !== -1) {
      this.setSlide(index);
      this.dataSource.next(this.currentSlide);
      return true;
    } else {
      return false;
    }
  }

  public nextSlide(): void {
    if (
      this.currentSlideIndex + 1 <
      this.presentationService.getPresentationSlides().length
    ) {
      if (
        this.skipSlide(this.currentSlideIndex + 1) &&
        this.currentSlideIndex + 2 <
          this.presentationService.getPresentationSlides().length
      ) {
        this.setSlide(this.currentSlideIndex + 2);
      } else {
        this.setSlide(this.currentSlideIndex + 1);
      }
    } else {
      this.logger.info(
        "SlideNavService.nextSlide() -> THIS IS THE LAST SLIDE! NO FURTHER NAVIGATION"
      );
    }
  }

  public previousSlide(): void {
    // Really Bad Code Ahead!
    if (this.skipSlide(this.currentSlideIndex - 1)) {
      if (DealersService.IS_MULTI_DEALERS) {
        this.currentSlideIndex = 0;
        this.navCtrl.navigateRoot("home/dealers", {
          queryParamsHandling: "merge",
          animationDirection: "forward",
        });
      } else if (this.currentSlideIndex - 2 >= 0) {
          this.setSlide(this.currentSlideIndex - 2, true);
      } else {
            this.logger.info(
              "SlideNavService.nextSlide() -> PREVIOUS SLIDE! THIS IS THE FIRST SLIDE! NO FURTHER BACK NAVIGATION"
            );
      }
      return; // kill the function now
    }
    //
    if (this.currentSlideIndex - 1 >= 0) {
        
      this.setSlide(this.currentSlideIndex - 1, true);
      // this.currentSlideIndex = this.currentSlideIndex - 1;
      // this.navCtrl.pop();
      // this.navCtrl.navigateBack(this.getSlideURL());
    } else {
      this.logger.info(
        "SlideNavService.nextSlide() -> THIS IS THE FIRST SLIDE! NO FURTHER BACK NAVIGATION"
      );
    }
  }

  // public resetInterest(): void {
  //   this.presentationService.interests = {};
  //   // this.presentationService.openSessionData.interest = {};
  // }

  public reset(): void {
    this.logger.info(
      "SlideNavService.reset()"
    );
    // this.resetInterest();
    this.presentationService.resetInterest();

    if (DealersService.IS_MULTI_DEALERS) {
      this.currentSlideIndex = 0;
      this.navCtrl.navigateRoot("home/dealers", {
        queryParamsHandling: "merge",
        animationDirection: "forward",
      });
    } else {
      this.firstSlide();
    }
  }

  public firstSlide(): void {
    this.setSlide(0, true);
  }

  public setSlide(slideIndex: number, navigateBack: boolean = false): void {
    this.currentSlideIndex = slideIndex;
    const nextSlide =
      this.presentationService.getPresentationSlides()[this.currentSlideIndex];
    // this.logger.info(
    //   "SlideNavService.setSlide() -> nextSlide.type: " + nextSlide.type
    // );

    const isSameRoute: boolean =
      this.currentSlide &&
      this.getSlideURL(this.currentSlide) === this.getSlideURL(nextSlide);
    this.currentSlide = nextSlide;
    //
    if (navigateBack && this.currentSlide.type === PagesEnums.P_VIDEO_PLAYER) {

    }

    // if its a navigation back and the previous slide is a Video, we skip it
    if (navigateBack && this.currentSlide.type === PagesEnums.P_VIDEO_PLAYER) {
      this.previousSlide();
      return;
    } else if (navigateBack && this.currentSlide.type === PagesEnums.S_START) {
      this.previousSlide();
      return;
    }
    this.stateService.saveSlide(this.currentSlide.uuid);
    //
    if (navigateBack) {
      this.navCtrl.navigateRoot(this.getCurrentSlideURL(isSameRoute), {
        queryParamsHandling: "merge",
        animationDirection: "back",
      });
    } else {
      this.navCtrl.navigateRoot(this.getCurrentSlideURL(isSameRoute), {
        queryParamsHandling: "merge",
        animationDirection: "forward",
      });
    }
  }

  public getCurrentSlideURL(isSameRoute: boolean): string {
    let slideURL: string = this.getSlideURL(this.currentSlide);
    if (isSameRoute) {
      slideURL += "/" + Date.now();
    }
    //
    return slideURL;
  }

  public getSlideURL(slide: SlideModel): string {
    this.logger.info(
      "SlideNavService.getSlideURL() -> slide: " + JSON.stringify(slide)
    );
    let slideURL: string;
    if (slide.type === PagesEnums.P_LOCAL_SELECT) {
      slideURL = "home/language";
    } else if (slide.type === PagesEnums.P_OPERATION_SELECT) {
      slideURL = "home/operation";
    } else if (slide.type === PagesEnums.P_YEAR_SELECT) {
      slideURL = "home/year";
    } else if (slide.type === PagesEnums.P_MAKER_SELECT) {
      slideURL = "home/maker";
    } else if (slide.type === PagesEnums.P_VIDEO_PLAYER) {
      slideURL = "home/video";
    } else if (slide.type === PagesEnums.P_MODEL_SELECT) {
      slideURL = "home/model";
    } else if (slide.type === PagesEnums.P_COLOR_SELECT) {
      slideURL = "home/color";
    } else if (slide.type === PagesEnums.S_LINES_POINTS) {
      slideURL = "home/info-points";
    } else if (slide.type === PagesEnums.S_LINES_POINTS_LINES) {
      slideURL = "home/info-points";
    } else if (slide.type === PagesEnums.S_LINES_POINTS_IMG) {
      slideURL = "home/info-points";
    } else if (slide.type === PagesEnums.S_HEAD_LINES_FOOT) {
      slideURL = "home/header-info-footer";
    } else if (slide.type === PagesEnums.S_CHECKBOXES) {
      slideURL = "home/customize-vehicle";
    } else if (slide.type === PagesEnums.S_CHECKBOXES_MEDIA) {
      slideURL = "home/checkboxes-media";
    } else if (slide.type === PagesEnums.S_LEFT_VIDEO_LINES) {
      slideURL = "home/coverage-video";
    } else if (slide.type === "S_START") {
      slideURL = "home/start";
    } else if (slide.type === PagesEnums.S_SLIM_IMG_LINES) {
      slideURL = "home/slim-image-text";
    } else if (slide.type === PagesEnums.S_TINTED_WINDOWS) {
      slideURL = "home/tinted-windows";
    } else if (slide.type === PagesEnums.S_IMG_IMG) {
      slideURL = "home/img-img";
    } else if (slide.type === PagesEnums.S_IMG_IMG_NEXT) {
      slideURL = "home/img-img-next";
    } else if (slide.type === PagesEnums.S_LINES_POINTS_2_COLUMNS) {
      slideURL = "home/points-2-columns";
    } else if (slide.type === PagesEnums.S_LINES_POINTS_3_COLUMNS) {
      slideURL = "home/points-3-columns";
    } else if (slide.type === PagesEnums.S_PACKAGE_BUILDER) {
      slideURL = "home/summary";
    } else if (slide.type === PagesEnums.P_THX) {
      slideURL = "home/thank-you";
    } else {
      slideURL = "/login";
    }
    return slideURL;
  }

  public setBackOrForwardClick(value: boolean): void {
    this.backOrForwardClick = value;
  }

  public getBackOrForwardClick(): boolean {
    return this.backOrForwardClick;
  }
}
