import { TranslateService } from "@ngx-translate/core";
import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";

@Pipe({
  name: "carPointPopup",
})
export class CarPointPopupPipe implements PipeTransform {
  constructor() {}

  transform(point: any): any {
    // card max width: 320px
    let newPoint = _.cloneDeep(point);
    // console.log("CarPointPopupPipe.transform()");
    // console.log("CarPointPopupPipe point: " + JSON.stringify(newPoint));
    newPoint.x += 32;
    newPoint.y -= 2;
    if (newPoint.x + 320 > screen.width) {
      newPoint.x -= newPoint.x + 320 - screen.width;
    }
    //
    if (newPoint.y + 320 > screen.height) {
      newPoint.y = screen.height - 320;
    }
    //
    return newPoint;
  }
}
