import { LoginPartnerModel } from "./../models/login-model";
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from "./storage.service";
import { FniApiService } from "./fni-api.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { map, catchError } from "rxjs/operators";
import { BehaviorSubject, throwError, Subject } from "rxjs";
import { LoginModel } from "../models/login-model";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private auth = new BehaviorSubject<boolean>(null);
  private logoutSource = new Subject<boolean>();
  authChange = this.auth.asObservable();
  logoutChange = this.logoutSource.asObservable();

  token: string = null;
  refreshToken: string;
  expireIn: string;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: FniApiService,
    private storageService: StorageService
  ) {}

  public login(logModel: LoginModel | LoginPartnerModel): any {
    const url: string = this.api.getLoginURL();

    return this.http.post<any>(url, logModel).pipe(
      map((data) => {
        // this.logger.info('AuthService.login() -> LOGIN SUCCESS!  data: ' + JSON.stringify(data));
        this.token = data.access_token;
        this.refreshToken = data.refresh_token;
        this.expireIn = data.expires_in;

        this.storageService.setData(StorageService.TOKEN_STORAGE, this.token);
        this.storageService.setData(
          StorageService.TOKEN_REFRESH_STORAGE,
          this.refreshToken
        );
        this.storageService.setData(
          StorageService.TOKEN_EXPIRE_STORAGE,
          this.expireIn
        );

        // console.log('this.token: ' + this.token);
        // console.log('this.refreshToken: ' + this.refreshToken);
        // console.log('this.expireIn: ' + this.expireIn);
        // console.log("LOGIN ERROR PATATE 403");
        this.auth.next(true);
        return data;
      }),
      catchError((error) => {
        // console.log(
        //   "LOGIN ERROR PATATE -> CATCHED ERROR... YES, IT WAS CATCHED"
        // );
        this.auth.next(false);
        return throwError(error);
      })
    );
  }

  public webSessionData(webSessionID: string): any {
    const url: string = this.api.webSessionURL() + webSessionID;

    return this.http.get<any>(url).pipe(
      map((data) => {
        this.logger.info(
          "AuthService.webSessionData() -> WEB SESSION SUCCESS!  data: " +
            JSON.stringify(data)
        );
        // this.token = data.access_token;
        // this.refreshToken = data.refresh_token;
        // this.expireIn = data.expires_in;

        // this.storageService.setData(StorageService.TOKEN_STORAGE, this.token);
        // this.storageService.setData(StorageService.TOKEN_REFRESH_STORAGE, this.refreshToken);
        // this.storageService.setData(StorageService.TOKEN_EXPIRE_STORAGE, this.expireIn);

        // console.log('this.token: ' + this.token);
        // console.log('this.refreshToken: ' + this.refreshToken);
        // console.log('this.expireIn: ' + this.expireIn);
        this.auth.next(true);
        return data;
      }),
      catchError((error) => {
        this.auth.next(false);
        return throwError(error);
      })
    );
  }

  public forgotPassword(userEmail: string, userLocal: string): any {
    const url: string = this.api.getForgotPasswordURL();
    return this.http
      .post<any>(url, { email: userEmail, local: userLocal })
      .pipe(
        map((data) => {
          // this.logger.info('AuthService.forgotPassword() -> data: ' + JSON.stringify(data));
          // this.auth.next(true);
          return data;
        }),
        catchError((error) => {
          this.auth.next(false);
          return throwError(error);
        })
      );
  }

  public getToken(): string {
    return this.token;
  }

  public hasToken(): boolean {
    // console.log('>>>> ' + this.token);
    return this.token !== null;
  }

  public async recoverFromStorage() {
    this.token = await this.storageService.getData(
      StorageService.TOKEN_STORAGE
    );
    this.refreshToken = await this.storageService.getData(
      StorageService.TOKEN_REFRESH_STORAGE
    );
    this.expireIn = await this.storageService.getData(
      StorageService.TOKEN_EXPIRE_STORAGE
    );
    return this.token;
  }
}
