import { AlertController } from "@ionic/angular";
import { PresentationService } from "./presentation.service";
import { FniApiService } from "./fni-api.service";
import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { HttpClient } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { throwError, BehaviorSubject } from "rxjs";
import { ColorModel } from "src/app/pages/color-select/color-select.page";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class VehiculesService {
  vehicules: VehiculeModel[];
  multiVehicules: any;
  multiModel: any;
  vehiculesCategories: VehiculeCategoryModel[] = [];
  years: string[];
  makers: any;
  original_makers: any = null;
  isMultiVehicules: boolean = false;

  constructor(
    private logger: NGXLogger,
    private translate: TranslateService,
    private http: HttpClient,
    public presentationService: PresentationService,
    private api: FniApiService,
    public alertController: AlertController
  ) {}

  public loadVehicules(): any {
    // this.logger.info(
    //   "VehiculesService.loadVehicules() presentation id:" +
    //     this.presentationService.presentationData.id
    // );
    const url: string = this.api
      .getVehiculesURL()
      .replace("${ID}", this.presentationService.presentationData.id);
    // this.logger.info("VehiculesService.loadVehicules() -> url: " + url);

    return this.http.get<any>(url).pipe(
      map((data) => {
        // this.logger.info(
        //   "VehiculesService.loadVehicules() -> SUCCESS!  data: " +
        //     JSON.stringify(data)
        // );

        if (data.car_collection) {
          this.isMultiVehicules = true;
          //   this.logger.info(
          //     "VehiculesService.loadVehicules() -> SUCCESS!  this.isMultiVehicules: " +
          //       this.isMultiVehicules
          //   );
          this.multiVehicules = data.car_collection;
          this.multiModel = data.model_collection;
          //   this.logger.info(
          //     "VehiculesService.loadVehicules() -> SUCCESS!  this.multiVehicules: " +
          //       JSON.stringify(this.multiVehicules)
          //   );
          //   this.logger.info(
          //     "VehiculesService.loadVehicules() -> SUCCESS!  this.multiModel: " +
          //       JSON.stringify(this.multiModel)
          //   );
          //   this.vehicules = this.multiVehicules;
          this.crunchMultiVehiculesMakers();
          this.crunchMultiVehiclesYears();
        } else {
          this.vehicules = data;

          this.logger.info("VehiculesService.loadVehicules() -> 1 " + data);
          if (this.vehicules && this.vehicules.length === 0) {
            this.showErrorMessage(this.translate.instant("ERRORS.NO_CAR"));
          }

          this.crunchVehiculesCategories();
          this.crunchVehiclesYears();
        }

        return data;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public loadVehiculesFull(year: string, makerID: string): any {
    // this.logger.info(
    //   "VehiculesService.loadVehiculesFull() presentation id:" +
    //     this.presentationService.presentationData.id
    // );
    const url: string = this.api
      .getVehiculesFullURL()
      .replace("${ID}", this.presentationService.presentationData.id)
      .replace("${YEAR}", year)
      .replace("${MAKER_ID}", makerID);
    // this.logger.info("VehiculesService.loadVehiculesFull() -> url: " + url);

    return this.http.get<any>(url).pipe(
      map((data) => {
        // this.logger.info(
        //   "VehiculesService.loadVehiculesFull() -> SUCCESS!  data: " +
        //     JSON.stringify(data)
        // );

        if (data.car_collection) {
          this.isMultiVehicules = true;
          //   this.logger.info(
          //     "VehiculesService.loadVehiculesFull() -> SUCCESS!  this.isMultiVehicules: " +
          //       this.isMultiVehicules
          //   );
          this.multiVehicules = data.car_collection;
          this.multiModel = data.model_collection;
          //   this.logger.info(
          //     "VehiculesService.loadVehiculesFull() -> SUCCESS!  this.multiVehicules: " +
          //       JSON.stringify(this.multiVehicules)
          //   );
          //   this.logger.info(
          //     "VehiculesService.loadVehiculesFull() -> SUCCESS!  this.multiModel: " +
          //       JSON.stringify(this.multiModel)
          //   );
          //   this.vehicules = this.multiVehicules;
          this.crunchMultiVehiculesMakers();
          this.crunchMultiVehiclesYears();
        } else {
          this.vehicules = data;
          this.logger.info(
            "PresentationService.loadPresentationByID() -> 2 " + this.vehicules
          );
          if (this.vehicules && this.vehicules.length === 0) {
            this.showErrorMessage(this.translate.instant("ERRORS.NO_CAR"));
          }
          this.crunchVehiculesCategories();
          this.crunchVehiclesYears();
        }

        return data;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public crunchVehiculesCategories(): void {
    // this.logger.info('VehiculesService.crunchVehiculesCategories() -> year ' + this.presentationService.openSessionData.year);
    this.vehiculesCategories = [];
    this.vehicules.map((data) => {
      //   this.logger.info("CRUNCH: " + JSON.stringify(data));

      //   this.logger.info(
      //     "typeof 1: " + typeof this.presentationService.openSessionData.year
      //   );
      //   this.logger.info("typeof 2: " + typeof data.year);
      //   this.logger.info(
      //     "typeof 3: " + typeof this.presentationService.openSessionData.year
      //   );
      // if the car is not from the year selected, we skip
      if (
        this.presentationService.openSessionData.year &&
        parseInt(data.year) !==
          parseInt(this.presentationService.openSessionData.year)
      ) {
        // this.logger.info(
        //   "SKIPPING YEAR: " +
        //     data.year +
        //     " ... not the selected year " +
        //     this.presentationService.openSessionData.year
        // );
        return;
      } else {
        // this.logger.info("SKIPPING YEAR: NO");
      }

      let cat = this.vehiculesCategories.find((category) => {
        return category.category === data.category;
      });

      //   this.logger.info("CRUNCH -> cat: " + JSON.stringify(cat));
      if (!cat) {
        cat = { category: data.category, title: data.category, vehicules: [] };
        this.vehiculesCategories.push(cat);
      }

      cat.vehicules.push(data);
    });

    this.vehiculesCategories.sort((a, b) => a.title.localeCompare(b.title));
    // this.logger.info('PresentationService.crunchVehiculesCategories() -> sorted: ' + JSON.stringify(this.vehiculesCategories));
  }

  public crunchMakerCars(maker): void {
    this.logger.info(
      "this.multiVehicules: " + JSON.stringify(this.multiVehicules)
    );
    this.logger.info(
      "this.presentationService.openSessionData.year: " +
        this.presentationService.openSessionData.year
    );
    // if (!this.multiVehicules) {
    //   return;
    // }

    this.logger.info("maker: " + maker);
    this.vehicules =
      this.multiVehicules[this.presentationService.openSessionData.year][maker];
    this.crunchVehiculesCategories();
  }

  public crunchMultiVehiculesMakers(): void {
    this.logger.info("crunchMultiVehiculesMakers()!!!!");
    
    this.makers = {};
    for (const year in this.multiVehicules) {
      this.makers[year] = [];
        // this.logger.info("crunchMultiVehiculesMakers() -> year: " + year);
      //   this.logger.info(
      //     "crunchMultiVehiculesMakers() -> item: " +
      //       JSON.stringify(this.multiVehicules[year])
      //   );
      for (const maker in this.multiVehicules[year]) {
        const makerModel = this.multiModel.find((item) => {
          //   this.logger.info(
          //     "crunchMultiVehiculesMakers() -> item: " + JSON.stringify(item)
          //   );
          return item.slug === maker;
        });
        // console.log("------");
        if (makerModel) {
          this.makers[year].push({
            name: makerModel.name,
            slug: makerModel.slug,
            logo: makerModel.logo,
            id: makerModel.id,
          });
          //   this.makers[year] = this.makers[year].concat(this.makers[year]);
          //   this.makers[year] = this.makers[year].concat(this.makers[year]);
          //   this.makers[year] = this.makers[year].concat(this.makers[year]);
          //   this.makers[year] = this.makers[year].concat(this.makers[year]);
          //   this.makers[year] = this.makers[year].concat(this.makers[year]);
          //   this.makers[year] = this.makers[year].concat(this.makers[year]);
          //   this.makers[year] = this.makers[year].concat(this.makers[year]);
        }
      }
      //
      //   this.makers.sort((a, b) => a.title.localeCompare(b.title));
      // this.makers.sort((one, two) => (one > two ? -1 : 1));
      for (const maker in this.makers) {
        this.makers[maker].sort((a, b) =>
          //   one.name < two.name ? -1 : 1;
          a.name.localeCompare(b.name)
        );
      }
      //   this.logger.info("sort() " + JSON.stringify(this.makers));
    }

    if (this.original_makers === null) {
        this.original_makers = _.cloneDeep(this.makers);
    }
    
    //
    this.logger.info(
      "crunchMultiVehiculesMakers() -> !!!! original_makers: " +
        JSON.stringify(this.original_makers)
    );
  }

  public crunchVehiclesYears(): void {
    this.years = [];
    this.vehicules.map((data) => {
      const year = this.years.find((y) => {
        return y === data.year;
      });
      if (!year) {
        this.years.push(data.year);
      }
    });

    this.logger.info(
      "PresentationService.crunchVehiclesYears() -> years: " +
        JSON.stringify(this.years)
    );
    this.years.sort((one, two) => (one > two ? -1 : 1));
  }

  public crunchMultiVehiclesYears(): void {
    this.years = [];
    // this.logger.info(
    //   "this.multiVehicules: " + JSON.stringify(this.multiVehicules)
    // );

    // this.logger.info("crunchMultiVehiclesYears() YEAR START LOOP! ");
    for (const year in this.multiVehicules) {
      //   this.logger.info("crunchMultiVehiclesYears() -> year: " + year);
      this.years.push(year);
    }
    // this.logger.info(
    //   "crunchMultiVehiclesYears() -> YEAR END LOOP! end result array: " +
    //     JSON.stringify(this.years)
    // );
    this.years.sort((one, two) => (one > two ? -1 : 1));
  }

  public getVehiculeByYear(year: string): any {
    return this.vehicules.filter((item) => {
      return item.year === year;
    });
  }

  //
  public async showErrorMessage(message: string) {
    // console.log("showErrorMessage()");
    let alert = await this.alertController.create({
      header: this.translate.instant("COMMON.ERROR_DIALOG_TITLE"),
      message: message,
      backdropDismiss: true,
      buttons: [
        {
          text: "OK",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            // console.log('Update Canceled');
            alert = null;
          },
        },
      ],
    });
    await alert.present();
  }
}

export interface VehiculeCategoryModel {
  category: string;
  title: string;
  vehicules: VehiculeModel[];
}

export interface VehiculeModel {
  id: string;
  title: string;
  category: string;
  // model: string;
  model_local: any;
  name: any;
  year: string;
  colors: ColorModel;
  availability: string;
  cover_filename: string;
  x: string;
  y: string;
  car_make_slug: string;
}
