import { DealersService } from "src/app/core/services/dealers.service";
// import { buildInfo } from './../../build';
import { ResetHotspotService } from "./../core/services/reset-hotspot.service";
import { LoadingIndicatorService } from "./../core/services/loading-indicator.service";
import { VehiculesService } from "./../core/services/vehicules.service";
import { StateServiceService } from "./../core/services/state-service.service";
import { StorageService } from "./../core/services/storage.service";
import { PresentationService } from "./../core/services/presentation.service";
import { AuthService } from "./../core/services/auth.service";
import { ActivatedRoute } from "@angular/router";
import { SlideNavService } from "./../core/services/slide-nav.service";
import { Platform, LoadingController } from "@ionic/angular";
import { Component, OnInit, HostListener } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { TranslateService } from "@ngx-translate/core";
import { PlatformLocation } from "@angular/common";
import { InternalPlayerService } from "../core/services/internal_player.service";

@Component({
  selector: "app-home",
  templateUrl: "home.page.html",
  styleUrls: ["home.page.scss"],
})
export class HomePage implements OnInit {
  appReady: boolean = false;
  loading: any;
  isIpad: boolean = false;
  isLucy: boolean = false;

  constructor(
    private logger: NGXLogger,
    private auth: AuthService,
    public presentationService: PresentationService,
    private slideNav: SlideNavService,
    private loadingController: LoadingController,
    private storageService: StorageService,
    private platform: Platform,
    private translate: TranslateService,
    private slideNavService: SlideNavService,
    private activatedRoute: ActivatedRoute,
    private loadingIndicator: LoadingIndicatorService,
    private location: PlatformLocation,
    private vehiculesService: VehiculesService,
    public resetHotspotService: ResetHotspotService,
    private stateService: StateServiceService,
    private internalPlayer: InternalPlayerService
  ) {
    //
    // this.logger.info('HomePage.constructor()');
    // location.onPopState( data => {
    //   console.log( ">>> onPopState(): ", data );
    // });
    // router.events
    //     .pipe(
    //       filter(
    //         ( event: any ) => {
    //           return( event instanceof NavigationStart );
    //         }
    //       )
    //     )
    //     .subscribe(
    //       ( event: NavigationStart ) => {
    //         console.group( "NavigationStart Event" );
    //         console.log( "navigation id:", event.id );
    //         console.log( "route:", event.url );
    //         console.log( "trigger:", event.navigationTrigger );
    //         if ( event.restoredState ) {
    //           console.warn(
    //             "restoring navigation id:",
    //             event.restoredState.navigationId
    //           );
    //         }
    //         console.groupEnd();
    //       }
    //     )
    //   ;
  }

  ngOnInit(): void {
    this.logger.info("HomePage.ngOnInit()");
    this.isIpad = this.platform.is("ipad");
    this.isLucy = this.presentationService.openSessionData.appIsInternalPlayer;
    // this.logger.info('HomePage.ngOnInit()-> buildInfo.version: ' + buildInfo.version);
    // this.logger.info('HomePage.ngOnInit()-> buildInfo.hash: ' + buildInfo.hash);

    this.logger.info(window.location.pathname);
    // if (window.location.pathname === "/home/dealers") {
    //   this.logger.info("IS DEALER PAGE, STOP PROCESS!");
    //   return;
    // }

    const webSessionID =
      this.activatedRoute.snapshot.queryParamMap.get("websessionuuid");
    this.logger.info("HomePage.ngOnInit() --> sessionID: " + webSessionID);

    if (webSessionID) {
      this.loadWebSessionData(webSessionID);
    } else {
      this.checkForUserData();
    }
    //
    this.internalPlayer.playerReady.subscribe((ready) => {
      this.logger.info("HomePage.ngOnInit() ->playerReady : " + ready);
      if (ready !== null) {
        this.appReady = true;
      }
    });
  }

  public loadWebSessionData(webSessionID: string): void {
    this.loadingIndicator.presentLoading("home page oad websession data 111");
    PresentationService.isWebsession = true;
    this.auth.webSessionData(webSessionID).subscribe(
      (response) => {
        if (response) {
          this.presentationService.presentationData =
            response.data.presentation;
          this.presentationService.openSessionData.maker =
            response.data.presentation.dealer.car_make.slug;

          this.presentationService.openSessionData.model =
            response.data.car_model;
          this.presentationService.openSessionData.color =
            response.data.car_color;
          this.presentationService.openSessionData.model.colors =
            response.data.car_color;
          this.presentationService.client = response.data.client;
          this.presentationService.sessionID = response.data.id;
          this.presentationService.sessionUUID = response.data.uuid;
          // tslint:disable-next-line: max-line-length
          this.presentationService.presentationSlides =
            response.data.presentation_branch.slides;

          // this.openSessionData.maker = this.presentationData.dealer.car_make.slug;
          this.logger.info(response.data.presentation.dealer.car_make);
          this.presentationService.openSessionData.maker =
            response.data.presentation.dealer.car_make.slug;
          //   console.log(response.data.dealer.car_make.slug);

          if (this.presentationService.presentationData.dark) {
            document.body.classList.add("dark");
          }

          this.translate.use(response.data.locale);

          this.stateService.saveState();
          this.presentationService.initBranch();
          this.slideNavService.start();

          setTimeout(() => {
            this.loadingIndicator.dismissLoading(
              "HomePage.loadWebSessionData().success"
            );
            // this.dismissLoading();
          }, 500);

          // TODO: Set local
        }
        // this.checkForUserData();
      },
      (error) => {
        this.logger.error("loadWebSessionData() -> " + JSON.stringify(error));
        this.loadingIndicator.dismissLoading("HomePage.loadWebSessionData().error");
        // this.dismissLoading();
        // TODO: Handle error
      }
    );
  }

  public async checkForUserData() {
    // this.logger.info('HomePage.checkForUserData() --> 1. this.auth.hasToken() : ' + this.auth.hasToken());
    if (!this.auth.hasToken()) {
      const storedToken = await this.auth.recoverFromStorage();
      // this.logger.info('HomePage.checkForUserData() --> 2. storedToken : ' + storedToken);

      // if (storedToken && !this.presentationService.presentationData) {
      //   this.loadCurrentPresentation();
      // }
    }

    // this.logger.info(
    //   "HomePage.checkForUserData() --> 3. this.presentationService.presentationData : " +
    //     JSON.stringify(this.presentationService.presentationData)
    // );
    //
    // this.logger.info(window.location.pathname);
    this.logger.info(
      "window.location.pathname !== /home/dealers : " +
        (window.location.pathname !== "/home/dealers")
    );
    if (
      !this.presentationService.presentationData &&
      window.location.pathname !== "/home/dealers"
    ) {
      const restoredState: boolean = await this.stateService.gestoredState();
      this.logger.info(
        "HomePage.checkForUserData() --> restoredState: " + restoredState
      );
      this.logger.info(
        "HomePage.checkForUserData() --> openSessionData: " +
          JSON.stringify(this.presentationService.openSessionData)
      );
      if (restoredState) {
        this.initSlides();
        if (this.presentationService.openSessionData.locale) {
          this.translate.use(this.presentationService.openSessionData.locale);
        }
      } else {
        this.loadCurrentPresentation();
      }
    }
  }

  // public async checkForUserData() {
  //   // const storedToken: string = await this.storageService.getData(StorageService.TOKEN_STORAGE);
  //   this.logger.info('HomePage.checkForUserData() -> storedToken: ' + storedToken);
  //   if (storedToken && !this.presentationService.presentationData) {
  //     this.loadCurrentPresentation();
  //   }
  // }

  public loadCurrentPresentation(): void {
    this.presentationService.loadPresentation().subscribe(
      (response) => {
        // this.dismissLoading();
        // this.initSlides();
        // this.stateService.saveState();
        this.logger.info(
          "HomePage.loadPresentation() -> maker: " +
            this.presentationService.openSessionData.maker
        );
        this.loadVehicles();
      },
      (error) => {
        // this.dismissLoading();
        this.loadingIndicator.dismissLoading(
          "HomePage.loadCurrentPresentation().error"
        );
        // TODO: Handle error
      }
    );
  }

  public loadVehicles(): void {
    this.vehiculesService.loadVehicules().subscribe(
      (response) => {
        this.logger.info(
          "HomePage.ngOnInit.loadVehicules() -> SUCCESS: " +
            JSON.stringify(response)
        );
        // this.vehiculesCategories = this.vehiculesService.vehiculesCategories.filter( item => {
        // });
        // this.currentCategory = this.vehiculesService.vehiculesCategories[0];

        // this.dismissLoading();
        this.loadingIndicator.dismissLoading("HomePage.loadVehicles().success");
        this.initSlides();
        this.stateService.saveState();
      },
      (error) => {
        this.logger.error(
          "HomePage.ngOnInit.loadVehicules() -> ERROR: " + JSON.stringify(error)
        );
        this.loadingIndicator.dismissLoading("HomePage.loadVehicles().error");
      }
    );
  }

  public async initSlides() {
    const storedSlide = await this.storageService.getData(
      StorageService.CURRENT_SLIDE
    );
    if (storedSlide) {
      // TODO:
      // const recoverSuccess = false; // this.slideNav.recover(storedSlide);
      const recoverSuccess = this.slideNav.recover(storedSlide);

      if (!recoverSuccess) {
        this.slideNav.start();
      }
    } else {
      this.slideNav.start();
    }
  }

  @HostListener("window:popstate", ["$event"]) onBrowserBackBtnClose(
    event: Event
  ) {
    console.log("back button pressed");
    // event.preventDefault();
    // this.slideNavService.previousSlide();
    this.slideNavService.setBackOrForwardClick(true);
  }

  public onHotspotLonPress(): void {
    this.logger.info("onHotspotLonPress()");
    this.slideNavService.reset();
  }
}
