import { TranslateService } from "@ngx-translate/core";
import { ElementRef, Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";

@Pipe({
  name: "carPoints",
})
export class CarPointsPipe implements PipeTransform {
  constructor() {}

  transform(point: any, imgContainerWidth: number = null): any {
    // image: 640 x 480
    let newPoint = _.cloneDeep(point);
    // console.log("CarPointsPipe.transform()");
    // console.log(imgContainerWidth);
    // console.log("screen.width: " + screen.width);
    // console.log("window.innerWidth: " + window.innerWidth);
    // console.log("screen.width > 640: " + (screen.width < 640));
    // console.log("BEFORE point: " + JSON.stringify(newPoint));
    if (screen.width < 640 && imgContainerWidth !== null) {
      //   console.log("-- X --");
      //   const ratio = screen.width / 640;
      const ratio = imgContainerWidth / 640;
      //   const ratio = window.innerWidth / 640;
      //   console.log("ratio: " + ratio);
      //   console.log("final x: " + newPoint.x * ratio);
      newPoint.x = newPoint.x * ratio;
      newPoint.y = newPoint.y * ratio;
    }
    newPoint.x = newPoint.x - 10;
    newPoint.y = newPoint.y - 10;
    // console.log("AFTER point: " + JSON.stringify(newPoint));
    // console.log("--------------");
    return newPoint;
  }
}
