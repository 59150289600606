import { Subscription } from "rxjs";
import { LoadingIndicatorService } from "./core/services/loading-indicator.service";

import { InternalPlayerEnum } from "./core/enums/fni-enum";
import { NetworkService } from "./core/services/network.service";
import { Plugins } from "@capacitor/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, HostListener } from "@angular/core";

import { Platform, NavController, AlertController } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { TranslateService } from "@ngx-translate/core";

import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { NGXLogger } from "ngx-logger";
import { InternalPlayerService } from "./core/services/internal_player.service";
import { PresentationService } from "./core/services/presentation.service";
import { Capacitor } from "@capacitor/core";

const { Network } = Plugins;

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  queryParamsSubscription: Subscription;
  interval: any;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private router: Router,
    private navCtrl: NavController,
    private logger: NGXLogger,
    private alertController: AlertController,
    private networkService: NetworkService,
    private screenOrientation: ScreenOrientation,
    private route: ActivatedRoute,
    private internalPlayerService: InternalPlayerService,
    private loadingIndicatorService: LoadingIndicatorService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.translate.setDefaultLang("fr-CA");
    this.translate.use("fr-CA");

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.initNetworkDetection();
      this.initInternalPlayerWatcher();
      this.logger.info(
        "AppComponent.initializeApp().platform.ready() -> platforms(): " +
          this.platform.platforms()
      );
      this.logger.info(
        "AppComponent.initializeApp().platform.ready() -> DATE: 23 fevrier 2022"
      );
      this.logger.info(
        "AppComponent.initializeApp().platform.ready() -> is desktop: " +
          this.platform.is("desktop")
      );
      this.logger.info(
        "AppComponent.initializeApp().platform.ready() -> is ios: " +
          this.platform.is("ios")
      );
      this.logger.info(
        "AppComponent.initializeApp().platform.ready() -> is ipad: " +
          this.platform.is("ipad")
      );
      this.logger.info(
        "AppComponent.initializeApp().platform.ready() -> is android: " +
          this.platform.is("android")
      );
      this.logger.info(
        "AppComponent.initializeApp().platform.ready() -> is tablet: " +
          this.platform.is("tablet")
      );

      const isNotSupported =
        (this.platform.is("ios") || this.platform.is("android")) &&
        (!this.platform.is("ipad") || !this.platform.is("tablet"));
      this.logger.info(
        "AppComponent.initializeApp().platform.ready() -> is NOT  SUPPORTED: " +
          isNotSupported
      );

      PresentationService.isMobile = Capacitor.isNative;
      this.logger.info("IS MOBILE: " + Capacitor.isNative);

      // on the ipad emulator -> ipad,ios,tablet,cordova,capacitor,mobile,hybrid
      // TODO: CHECK FOR UNSUPPORTED DEVICE & REDIRECT TO NO SUPPORTED PAGE, OTHERWISE CALL checkForUserData()
      // this.logger.info('....... mobile: ' + this.platform.is('mobile'));
      // this.logger.info('....... tablet: ' + this.platform.is('tablet'));
      if (isNotSupported) {
        // TODO: UNCOMMENT
        // this.navCtrl.navigateRoot("/not-supported");
      } else {
      }
      // if ( this.platform.is('mobile') ) {
      //   this.logger.info('AppComponent.initializeApp().platform.ready() -> IS MOBILE...LOCKING SCREEN TP LANDSCAPE');
      // this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
      // }
      // else {
      //   this.logger.info('AppComponent.initializeApp().platform.ready() -> IS NOT MOBILE!!!');
      // }
    });
  }

  public initInternalPlayerWatcher(): void {
    this.queryParamsSubscription = this.route.queryParams.subscribe(
      (params) => {
        console.log("----------- START");
        //   console.log(this.route.snapshot.pathFromRoot);
        //   console.log(params);
        //   console.log("----------- END");
        if (
          params.internal_player &&
          (params.internal_player === InternalPlayerEnum.LUCY ||
            params.internal_player === InternalPlayerEnum.FNI ||
            params.internal_player === InternalPlayerEnum.ACTIVX)
        ) {
          let error = false;
          if (params.host_url === null) {
            this.showErrorMessage("Le paramètre d'URL host_url est null");
            error = true;
            console.log("Le paramètre d'URL host_url est null");
          } else {
            this.internalPlayerService.setHostURL(params.host_url);
          }
          if (params.api_url === null) {
            this.showErrorMessage("Le paramètre d'URL api_url est null");
            error = true;
            console.log("Le paramètre d'URL api_url est null");
          } else {
            this.internalPlayerService.setApiURL(params.api_url);
          }
          if (error) {
            return;
          }
          //
          this.loadingIndicatorService.presentLoading(
            "appcomponent 151 setTimeout() FNIP_ON_PLAYER_READY"
          );
          //
        //   this.interval = setInterval(() => this.pingReadyLucy, 4000);
          this.pingReadyLucy();
          //   setTimeout(() => {
          //     this.internalPlayerService.setPlayerReady(true);
          //     this.internalPlayerService.onSendPostMessage(
          //       InternalPlayerEnum.FNIP_ON_PLAYER_READY
          //     );

          //     this.logger.info(
          //       "AppComponent.initInternalPlayerWatcher() -> setTimeout() -> FNIP_ON_PLAYER_READY"
          //     );
          //   }, 900)
          //
          if (this.queryParamsSubscription) {
            this.queryParamsSubscription.unsubscribe();
          }
        } else {
          this.internalPlayerService.setPlayerReady(false);
          // this.loadingIndicatorService.dismissLoading();
        }
      }
    );

    //
    this.internalPlayerService.loginSuccess.subscribe((success) => {
      this.logger.info(
        "AppComponent.initInternalPlayerWatcher() -> internal player loginSuccess : " +
          success
      );

      if (success) {
        this.loadingIndicatorService.dismissLoading(
          "internalPlayerService.loginSuccess"
        );
        // fail safe
        // setTimeout(() => {
        //   this.loadingIndicatorService.dismissLoading(
        //     "internalPlayerService.loginSuccess.setTimeout"
        //   );
        // }, 1000);
      }
    });
  }

  public pingReadyLucy(): void {
    this.logger.info(
      "AppComponent.pingReadyLucy() -> setTimeout() -> FNIP_ON_PLAYER_READY -> this.internalPlayerService.initDataReceived: " +
        this.internalPlayerService.initDataReceived
    );
    setTimeout(() => {
      this.internalPlayerService.setPlayerReady(true);
      this.internalPlayerService.onSendPostMessage(
        InternalPlayerEnum.FNIP_ON_PLAYER_READY
      );

      this.logger.info(
        "AppComponent.pingReadyLucy() -> setTimeout() -> FNIP_ON_PLAYER_READY"
      );
    }, 500);


    setTimeout(() => {
      this.internalPlayerService.setPlayerReady(true);
      this.internalPlayerService.onSendPostMessage(
        InternalPlayerEnum.FNIP_ON_PLAYER_READY
      );

      this.logger.info(
        "AppComponent.pingReadyLucy() -> setTimeout() -> IS INIT? " + this.internalPlayerService.initDataReceived
      );
      if(!this.internalPlayerService.initDataReceived) {
        this.logger.info(
          "AppComponent.pingReadyLucy() -> setTimeout() -> INIT DATA NOT RECEIEVED! PINGING AGAIN ... " +
            this.internalPlayerService.initDataReceived
        );
        this.pingReadyLucy();
      }
    }, 3000);
  }

  public async initNetworkDetection() {
    const handler = Network.addListener("networkStatusChange", (status) => {
      console.log("Network status changed", status);
      this.networkService.connected = status.connected;
      if (!status.connected) {
        this.showNoNetworkConnection();
      }
    });
    // To stop listening:
    // handler.remove();

    // Get the current network status
    const nStatus = await Network.getStatus();
    this.networkService.connected = nStatus.connected;
    if (!nStatus.connected) {
      setTimeout(() => {
        this.showNoNetworkConnection();
      }, 1000);
    }

    //
    this.networkService.noConnection.subscribe((data) => {
      this.logger.info("**** noConnection!");
      this.showNoNetworkConnection();
    });
  }

  public async showNoNetworkConnection() {
    console.log("showNoNetworkConnection()");
    let alert = await this.alertController.create({
      header: this.translate.instant("COMMON.NO_INTERNET_TITLE"),
      message: this.translate.instant("COMMON.NO_INTERNET"),
      backdropDismiss: true,
      buttons: [
        {
          text: "OK",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            // console.log('Update Canceled');
            alert = null;
          },
        },
      ],
    });
    await alert.present();
  }

  public async showErrorMessage(message: string) {
    console.log("showErrorMessage()");
    let alert = await this.alertController.create({
      header: this.translate.instant("COMMON.ERROR_DIALOG_TITLE"),
      message: message,
      backdropDismiss: true,
      buttons: [
        {
          text: "OK",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            // console.log('Update Canceled');
            alert = null;
          },
        },
      ],
    });
    await alert.present();
  }

  @HostListener("window:message", ["$event"])
  onMessage(event: MessageEvent) {
    this.internalPlayerService.onMessage(event);
    // console.log("2ANGULAR ALL onMessage()");
    // if (
    //   event.data &&
    //   event.data.type &&
    //   (event.data.type === "webpackOk" || event.data.type === "webpackInvalid")
    // ) {
    //   return;
    // }
    // //
    // if (event.type && event.type === "message") {
    //   console.log("2ANGULAR onMessage()");

    //   console.log("2ANGULAR Message received type: " + event.type);
    //   console.log("2ANGULAR Message received from: " + event.origin);
    //   //   console.log("ANGULAR Message: " + event.data);
    //   //   console.log("ANGULAR Message.name: " + event.data.name);
    //   //   console.log("ANGULAR Message.data: " + event.data.data);
    //   // console.log("Message JSON parse: " + JSON.parse(event.data));
    //   this.logger.info("2ANGULAR DATA RECEIVED: " + JSON.stringify(event.data));
    //   console.log("---- 2END ----");

    //   if (event.data.name === InternalPlayerEnum.FNIP_SET_PLAYER_INIT_DATA) {
    //     console.log("2IS FNIP_SET_PLAYER_INIT_DATA!!!");
    //     console.log(event.data);
    //   }
    // }
  }
}
