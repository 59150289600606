// export const environment = {
//   production: false,
//   protocol: "https://",
//   domainPrefix: "api",
//   domain: "fni.test",
//   apiEnv: null,
//   internalPlayer: "admin.fni.test",
// };

// export const firebaseConfig = {
//   apiKey: "AIzaSyCeGXXPo7yK21llyExihU5-oSy1XHSLuuI",
//   authDomain: "ninja-fni-app.firebaseapp.com",
//   databaseURL: "https://ninja-fni-app.firebaseio.com",
//   projectId: "ninja-fni-app",
//   storageBucket: "ninja-fni-app.appspot.com",
//   messagingSenderId: "871297046420",
//   appId: "1:871297046420:web:f92ad8a4964ca217eea181",
// };

export const environment = {
  production: false,
  protocol: "https://",
  domainPrefix: "api",
  domain: "fniprestige.app",
  apiEnv: "dev",
  internalPlayer: "admin.fni.test",
};

export const firebaseConfig = {
  apiKey: "AIzaSyCeGXXPo7yK21llyExihU5-oSy1XHSLuuI",
  authDomain: "ninja-fni-app.firebaseapp.com",
  databaseURL: "https://ninja-fni-app.firebaseio.com",
  projectId: "ninja-fni-app",
  storageBucket: "ninja-fni-app.appspot.com",
  messagingSenderId: "871297046420",
  appId: "1:871297046420:web:f92ad8a4964ca217eea181",
};
