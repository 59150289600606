import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class StorageService {


  static REMEMBER_ME_STORAGE: string = 'REMEMBER_ME_STORAGE';
  static REMEMBER_ME_PA_STORAGE: string = 'REMEMBER_ME_PA_STORAGE';
  static TOKEN_STORAGE: string = 'TOKEN_STORAGE';
  static TOKEN_REFRESH_STORAGE: string = 'TOKEN_REFRESH_STORAGE';
  static TOKEN_EXPIRE_STORAGE: string = 'TOKEN_EXPIRE_STORAGE';
  static CURRENT_SLIDE: string = 'CURRENT_SLIDE';
  static INTERESTS: string = 'INTERESTS';
  static STATE_DATA: string = 'STATE_DATA';

  constructor(private logger: NGXLogger) {

  }

  public async setData(storeKey: string, data: string) {
    await Storage.set({
      key: storeKey,
      value: data
    });
  }

  public async getData(storeKey: string) {
    const ret = await Storage.get({ key: storeKey });
    // const user = JSON.parse(ret.value);
    return ret.value;
  }

  public async removeData(storeKey: string) {
    await Storage.remove({ key: storeKey });
  }

}
