import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent implements OnInit {

  public error: any;
  public errorsData: any[] = [];
  public useError456Template: boolean = false;

  constructor(public dialogRef: MatDialogRef<ErrorDialogComponent>)
    {
      this.errorsData = [];
    }

  ngOnInit() {}

  public setError( error: any ): void
    {
      this.error = error;
      for (const each of Object.keys(this.error.error.errors))
      {
          // console.log(each);
        this.errorsData.push({key: each, data: this.error.error.errors[each]});
      }
      // console.log("errors data: " + JSON.stringify(this.errorsData));
    }

}
