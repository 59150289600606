import { SlideNavService } from './../services/slide-nav.service';
import { Observable, Observer } from 'rxjs';
import {Injectable, Injector} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class SlideCanDeactivateGuard implements CanDeactivate<ISlideCanDeactivateComponent>
{

    // constructor(public inj: Injector, public messageService: CanDeactivateMessageService)
    // {
    // }
    constructor(public slideNavService: SlideNavService)
    {
    }

    canDeactivate(component: ISlideCanDeactivateComponent,
                  route: ActivatedRouteSnapshot,
                  state: RouterStateSnapshot)
    {
        const url: string = state.url;
        console.log('SlideCanDeactivateGuard.canDeactivate() -> getBackOrForwardClick(): ' + this.slideNavService.getBackOrForwardClick());
        // console.log('CanDeactivateGuard.canDeactivate() -> component.canDeactivate(): ' + component.canDeactivate());
        // if (!component.canDeactivate())
        // {
        //     // const message: CanDeactivateMessageModel = component.canDeactivateMessage();
        //     return new Observable((observer: Observer<boolean>) => {
        //         this.messageService.showAlert({
        //             title: message.title,
        //             message: message.message,
        //             accept: () => {
        //                 observer.next(true);
        //                 observer.complete();
        //             },
        //             reject: () => {
        //                 observer.next(false);
        //                 observer.complete();
        //             }
        //         });
        //       });
        // }
        if (this.slideNavService.getBackOrForwardClick() ) {
            this.slideNavService.setBackOrForwardClick(false);
            return false;
        }

        return true; // component.canDeactivate ? component.canDeactivate() : true;
    }
}


export interface ISlideCanDeactivateComponent
{
    canDeactivate(): boolean;
    // canDeactivateMessage(): CanDeactivateMessageModel;
}
