import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  static LANG_ID: string = '${LANG}';

  static TEST_VIDEO: string = 'videos/py_${LANG}.mp4';

  private playVideoSource = new Subject<VideoEvent>();
  playVideo = this.playVideoSource.asObservable();

  constructor(private logger: NGXLogger,
              private translate: TranslateService,
              private afStorage: AngularFireStorage) {

  }

  public play(videoID: string): void {
    // const id: string = videoID.replace(VideoService.LANG_ID, this.translate.currentLang);
    this.logger.info('VideoService.play() -> videoID: ', videoID);
    const evt: VideoEvent = {
      slug: videoID.replace(VideoService.LANG_ID, this.formatLang(this.translate.currentLang)),
      url: null,
      navigatoTo: null
    };

    // if (id) {
    //   evt.slug = id;
    // }

    // TODO: asyn get final url from firbase and then dispatch/next the event
    this.logger.info('VideoService.play() -> evt.url: ', evt.slug);
    const ref = this.afStorage.ref(evt.slug);
    const subRef = ref.getDownloadURL().subscribe( url => {
      evt.url = url;
      this.playVideoSource.next(evt);
      subRef.unsubscribe();
    });
    // this.playVideoSource.next(evt);
  }

  public formatLang(lang: string): string {
    return lang.replace('-', '_');
  }
}

export interface VideoEvent {
  slug: string;
  url: string;
  navigatoTo: string | null;
}
