import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResetHotspotService {

  enabled = true;

  constructor() { }

  public setEnabled(value: boolean): void {
    this.enabled = value;
  }
}
