export interface LoginModel {
  username: string;
  password: string;
  client_id: LoginEnum.CLIENT_ID;
  client_secret: LoginEnum.CLIENT_SECRET;
  grant_type: LoginEnum.GRANT_TYPE;
  scope: LoginEnum.SCOPE;
}

export interface LoginPartnerModel {
  client_id: string;
  client_secret: string;
  grant_type: LoginPartnerEnum.GRANT_TYPE;
  //   scope: LoginPartnerEnum.SCOPE;
}

export enum LoginEnum {
  CLIENT_ID = 1,
  CLIENT_SECRET = "DzfC5qXVI0xTPtZD9VgcNSb7cWoQQqXemJmbIt0C",
  GRANT_TYPE = "password",
  SCOPE = "",
}

export enum LoginPartnerEnum {
  CLIENT_ID = 0,
  CLIENT_SECRET = "",
  GRANT_TYPE = "client_credentials",
  SCOPE = "",
}
