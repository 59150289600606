import { PresentationService } from "./presentation.service";
import { FniApiService } from "./fni-api.service";
import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { HttpClient } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { throwError, BehaviorSubject } from "rxjs";
import { ColorModel } from "src/app/pages/color-select/color-select.page";

@Injectable({
  providedIn: "root",
})
export class DealersService {
  static IS_MULTI_DEALERS: boolean = false;

  dealers: any[];
  selectedDealer: any;

  constructor(
    private logger: NGXLogger,
    private translate: TranslateService,
    private http: HttpClient,
    public presentationService: PresentationService,
    private api: FniApiService
  ) {}

  public loadDealers(): any {
    const url: string = this.api.getDealersURL();
    return this.http.get<any>(url).pipe(
      map((data) => {
        this.logger.info(
          "DealersService.loadDealers() -> SUCCESS!  data: " +
            JSON.stringify(data)
        );
        //
        // if (
        //   (data.dealer === null || data.dealer.data === null) &&
        //   data.dealers !== null &&
        //   data.dealers.data !== null &&
        //   data.dealers.data.length > 0
        // ) {
        //   data.dealer = data.dealers.data[0];
        // }

        this.dealers = data.dealers.data;
        if (this.dealers && this.dealers.length > 0) {
          if (data.dealer && data.dealer.data) {
            this.dealers.unshift(data.dealer.data);
          }
          this.presentationService.selectedDealer = data.dealers.data[0];
          this.logger.info(
            "loadDealers() DEALER WATCH: " +
              JSON.stringify(this.presentationService.selectedDealer)
          );
          DealersService.IS_MULTI_DEALERS = true;
        }
        if (
          data.dealer &&
          data.dealer.data &&
          data.dealer.data.current_presentation &&
          data.dealer.data.current_presentation.dark
        ) {
          document.body.classList.add("dark");
          this.logger.info(
            "DealersService.loadDealers() -> DARK: " +
              data.dealer.data.current_presentation.dark
          );
        }

        return data;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}

export interface VehiculeCategoryModel {
  category: string;
  title: string;
  vehicules: VehiculeModel[];
}

export interface VehiculeModel {
  id: string;
  title: string;
  category: string;
  // model: string;
  model_local: any;
  name: any;
  year: string;
  colors: ColorModel;
  availability: string;
  cover_filename: string;
  x: string;
  y: string;
}
