import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  connected: boolean = true;
  private dataSource = new Subject();
  noConnection = this.dataSource.asObservable();

  constructor() { }

  public triggerNoConnection(): void {
    this.dataSource.next();
  }
}
