import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'platformCss'
})
export class PlatformCssPipe implements PipeTransform {

  transform(value: any, prefix: string): string {
    // const sLang = this.translate.currentLang.replace('-', '_');
    // let finalPrefix: string = '';
    // if (prefix){
    //   finalPrefix = prefix + '_';
    // }
    // const nString = finalPrefix + sLang;
    return value;
  }

}
