// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  protocol: "https://",
  domainPrefix: "api",
  domain: "fniprestige.app",
  apiEnv: "dev",
  internalPlayer: "admin.fni.test",
};

export const firebaseConfig = {
  apiKey: "AIzaSyCeGXXPo7yK21llyExihU5-oSy1XHSLuuI",
  authDomain: "ninja-fni-app.firebaseapp.com",
  databaseURL: "https://ninja-fni-app.firebaseio.com",
  projectId: "ninja-fni-app",
  storageBucket: "ninja-fni-app.appspot.com",
  messagingSenderId: "871297046420",
  appId: "1:871297046420:web:f92ad8a4964ca217eea181",
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
