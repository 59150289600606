export enum PagesEnums {
  // NEW
  S_LINES_POINTS = "S_LINES_POINTS",
  S_LINES_POINTS_LINES = "S_LINES_POINTS_LINES",
  S_LINES_POINTS_IMG = "S_LINES_POINTS_IMG",
  S_HEAD_LINES_FOOT = "S_HEAD_LINES_FOOT",
  S_CHECKBOXES = "S_CHECKBOXES",
  S_CHECKBOXES_MEDIA = "S_CHECKBOXES_MEDIA",
  S_LEFT_VIDEO_LINES = "S_LEFT_VIDEO_LINES",
  S_SLIM_IMG_LINES = "S_SLIM_IMG_LINES",
  S_PACKAGE_BUILDER = "S_PACKAGE_BUILDER",
  S_TINTED_WINDOWS = "S_TINTED_WINDOWS",
  S_IMG_IMG = "S_IMG_IMG",
  S_IMG_IMG_NEXT = "S_IMG_IMG_NEXT",
  S_LINES_POINTS_2_COLUMNS = "S_LINES_POINTS_2_COLUMNS",
  S_LINES_POINTS_3_COLUMNS = "S_LINES_POINTS_3_COLUMNS",

  P_DEALERS_SLIDE = "P_DEALERS_SLIDE",
  P_LOCAL_SELECT = "P_LOCAL_SELECT",
  P_OPERATION_SELECT = "P_OPERATION_SELECT",
  P_YEAR_SELECT = "P_YEAR_SELECT",
  P_MAKER_SELECT = "P_MAKER_SELECT",
  P_VIDEO_PLAYER = "P_VIDEO_PLAYER",
  P_MODEL_SELECT = "P_MODEL_SELECT",
  P_COLOR_SELECT = "P_COLOR_SELECT",
  P_THX = "P_THX",
  S_START = "S_START",

  // OLD
  S_PYI_1 = "S_PYI_1",
}

export enum FNIEnums {
  OP_LEASE = "OP_LEASE",
  OP_RETAIL = "OP_RETAIL",
  OP_CASH = "OP_CASH",
}

export enum InternalPlayerEnum {
  LUCY = "LUCY",
  FNI = "FNI",
  ACTIVX = "ACTIVX",
  //
  FNIP_ON_PLAYER_READY = "FNIP_ON_PLAYER_READY", // on action from player
  FNIP_SET_PLAYER_INIT_DATA = "FNIP_SET_PLAYER_INIT_DATA", // set data in player
  FNIP_ON_DEALER_USER_AUTHENTICATED = "FNIP_ON_DEALER_USER_AUTHENTICATED",
  FNIP_ON_SESSION_START = "FNIP_ON_SESSION_START",
  FNIP_ON_SESSION_END = "FNIP_ON_SESSION_END",
  FNIP_ON_RESET_PRESENTATION = "FNIP_ON_RESET_PRESENTATION",
  FNIP_ON_SHARE = "FNIP_ON_SHARE",
}
