import { PresentationService } from './../services/presentation.service';
import { ErrorDialogComponent } from './../../components/error-dialog/error-dialog.component';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpHeaderResponse,
    HttpSentEvent, HttpProgressEvent, HttpResponse, HttpUserEvent,
    HttpErrorResponse } from '@angular/common/http';
import {Router} from '@angular/router';
import {Injectable, Injector} from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import { AlertController, NavController } from '@ionic/angular';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class JwtInterceptor implements HttpInterceptor
{

    constructor(public inj: Injector,
                public logger: NGXLogger)
    {
    }

    // tslint:disable-next-line: max-line-length
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any>
    {
        // this.logger.info("-> JwtInterceptor.intercept()");
        const router = this.inj.get(Router);
        const navCtrl = this.inj.get(NavController);
        const alertController = this.inj.get(AlertController);
        const dialogController = this.inj.get(MatDialog);
        // const presentation = this.inj.get(PresentationService);

        return next.handle(request)
            .pipe(
                catchError( err => {

                    if (err instanceof HttpErrorResponse)
                    {
                        this.logger.error('ERROR! JwtInterceptor.intercept() - Error: ' + JSON.stringify(err));

                        switch ((err as HttpErrorResponse).status)
                        {
                            case 401:
                                this.logger.info('-> JwtInterceptor.intercept() - Error 401! -> url: ' + err.url);
                                if (!PresentationService.isWebsession){
                                    navCtrl.navigateForward( 'login' );
                                }
                                // navCtrl.navigateForward( 'login' );
                                return throwError(err);
                            case 400:
                                // this.logger.info('-> JwtInterceptor.intercept() - Error 400! : ' +  this.tokenSubject );
                                this.logger.info('-> JwtInterceptor.intercept() - Error 400!');
                                // this.authService.softLogout();

                                // THIS LINE IS A TEST (EDIT: SEEM TO WORK)
                                // this.tokenSubject.next('CANCEL');
                                return throwError(err);
                            case 404:
                                return throwError(err);
                            case 500:
                                // TODO: add error 500 page
                                // router.navigateByUrl(  'erreur-500');
                                // this.handle500Error(err, request, next, dialogController);
                                this.handle500Error(request, next, alertController, err);
                                return throwError(err);
                            case 503 :
                                this.handle503Error(request, next, alertController, err);
                                return throwError(err);
                            case 429:
                                return throwError(err);
                            case 444:
                                return throwError(err);
                            case 422:
                                this.handle422Error(err, request, next, dialogController);
                                return throwError(err);
                                // return throwError(err);
                            case 456:
                                this.handle456Error(request, next, alertController, err);
                                return throwError(err);
                            case 488:
                                // router.navigateByUrl(AppRoutes.ERROR_429);
                                this.handle488Error(request, next, alertController, err);
                                return throwError(err);
                            case 504:
                                return throwError(err);
                            case 0:
                                this.logger.info('-> JwtInterceptor.intercept() - **** Error 0! ****');
                                return throwError(err);
                            default:
                                this.logger.info('JwtInterceptor.intercept() -> UNHANDLED ERROR CODE, DEFAULT THROW ERROR!');
                                return throwError(err);
                        }
                    } else {
                        return throwError(err);
                    }
                }));
    }


    private async handle488Error(request: HttpRequest<any>, next: HttpHandler, alertController: AlertController, error: HttpErrorResponse)
    {
        let alert = await alertController.create({
            header: error.error.title,
            message: error.error.message, // 'Erreur 488',
            backdropDismiss: false,
            buttons: [{
              text: 'OK',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                // console.log('Update Canceled');
                alert = null;
              }
            }
          ]
          });
        await alert.present();
    }

    private async handle456Error(request: HttpRequest<any>, next: HttpHandler, alertController: AlertController, error: HttpErrorResponse)
    {
        // TODO: add propoer test in alert
        let alert = await alertController.create({
            header: 'Erreur',
            message: error.error.message, // 'Erreur 456',
            backdropDismiss: false,
            buttons: [{
              text: 'OK',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                // console.log('Update Canceled');
                alert = null;
              }
            }
          ]
          });
        await alert.present();
    }

    private async handle503Error(request: HttpRequest<any>, next: HttpHandler, alertController: AlertController, error: HttpErrorResponse)
    {
        // TODO: add propoer test in alert
        let alert = await alertController.create({
            header: 'Erreur',
            message: 'Maintenance',
            backdropDismiss: false,
            buttons: [{
              text: 'OK',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                // console.log('Update Canceled');
                alert = null;
              }
            }
          ]
          });
        await alert.present();
    }

    
    private async handle422Error(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler, dialogController: MatDialog)
    {
        const dialogRef = dialogController.open(ErrorDialogComponent, {
          });

        dialogRef.componentInstance.setError(error);
    }


    private async handle500Error(request: HttpRequest<any>, next: HttpHandler, alertController: AlertController, error: HttpErrorResponse)
    {
        let alert = await alertController.create({
            header: 'Erreur 500',
            message: error.error.message, // 'Erreur 500',
            backdropDismiss: false,
            buttons: [{
              text: 'OK',
              role: 'cancel',
              cssClass: '',
              handler: () => {
                // console.log('Update Canceled');
                alert = null;
              }
            }
          ]
          });
        await alert.present();
    }

    
}
